import create from 'zustand'

const useStore = create((set) => ({
    selectedFile: [],
    setSelectedFile: (o) => {
        set((state) => ({
            selectedFile: [...state.selectedFile, o]
        }))
    },
    setSelectedFileArray: () => {
        set((state) => ({
            selectedFile: [...state.selectedFile]
        }))
    },
    removeSelectedFile: (id) => {
        set((state) => ({
            selectedFile: state.selectedFile.filter((file, index) => index !== id),
        }));
    },
    clearSelectedFile: () => {
        set((state) => ({
            selectedFile: []
        }))
    }
}))

export const useGlobalStore = useStore