import {gql} from '@apollo/client';
import {USER_ENTITY} from "../entities/userEntity";

export const UPDATE_NOTIFICATION_STATE = gql`
  mutation updateUserNotificationState($input: UserNotificationInput!, $_id: String!) {
    updateUserNotificationState(input: $input, _id: $_id) {
        ...UserEntity
    }
  }
  ${USER_ENTITY}
`;