import moment from "moment/moment";
import {getDurationInSecondsInt} from "../../../dateFunctions";
import {Box} from "@mui/material";
import ExcelExport from "../../common/ExcelExport";

const TaskHourExport = (props) => {
    let sortedTimes = props.times.reduce(function (o, cur) {
        if (!o) return o
        var occurs = []
        if(cur.type === "CHECKINCHECKOUT") {
            if(moment(cur.starttime).format('MMMM YYYY') === props.month) {
                occurs = o.find(ob => (ob?.customer && cur.task?.customer) && (ob?.customer.trim() === cur.task?.customer.trim()) && moment(ob.month).isSame(moment(cur.starttime), 'month'));
            }
        }

        if (occurs) {
            if (cur.endtime) {
                if(cur.type === "CHECKINCHECKOUT") {
                    occurs.worktime = occurs.worktime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
            }
        } else {
            var obj = {
                month: cur.starttime,
                customer: cur.task?.customer,
                worktime: cur.endtime && cur.type === "CHECKINCHECKOUT" && getDurationInSecondsInt(cur.starttime, cur.endtime),
            };
            o = o.concat(obj);
        }
        return o;
    }, [])

    let otherTimes = props.times.reduce(function (o, cur) {
        if (!o) return o
        var occurs = []
        if(moment(cur.starttime).format('MMMM YYYY') === props.month) {
            occurs = o.find(ob => moment(ob.month).isSame(moment(cur.starttime), 'month'));
        }

        if (occurs) {
            if (cur.endtime) {
                if (cur.type === "BREAK") {
                    occurs.breaktime = occurs.breaktime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
                if (cur.type === "DRIVE") {
                    occurs.drivetime = occurs.drivetime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
                if (cur.type === "LAGER") {
                    occurs.lagertime = occurs.lagertime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
                if (cur.type === "HAENDLER") {
                    occurs.haendlertime = occurs.haendlertime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
            }
        } else {
            var obj = {
                month: cur.starttime,
                breaktime: cur.endtime && cur.type === "BREAK" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                drivetime: cur.endtime && cur.type === "DRIVE" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                lagertime: cur.endtime && cur.type === "LAGER" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                haendlertime: cur.endtime && cur.type === "HAENDLER" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
            };
            o = o.concat(obj);
        }
        return o;
    }, [])

    const generatePayload = (times) => {
        let otherTimesArray = otherTimes
        let payload = []
        times.map(time => {
            payload.push({
                Kunde: time.customer,
                Stunden: moment.duration((time.worktime), "seconds").format("hh:mm", {
                    trim: false
                }),
                Fahrtzeiten_Anteilig: moment.duration((otherTimesArray[0].drivetime/times.length), "seconds").format("hh:mm", {
                    trim: false
                }),
                Lagerzeiten_Anteilig: moment.duration((otherTimesArray[0].lagertime/times.length), "seconds").format("hh:mm", {
                    trim: false
                }),
                Händlerzeiten_Anteilig: moment.duration((otherTimesArray[0].haendlertime/times.length), "seconds").format("hh:mm", {
                    trim: false
                }),
                Gesamt: moment.duration((time.worktime) + (otherTimesArray[0].drivetime/times.length) + (otherTimesArray[0].lagertime/times.length) + (otherTimesArray[0].haendlertime/times.length), "seconds").format("hh:mm", {
                    trim: false
                })
            })
        })
        return payload
    }

    return (
        <Box>
            <ExcelExport headline={"Auftragsstunden " + props.month} excelData={generatePayload(sortedTimes)} fileName={"Zusammenfassung Auftragsstunden " + props.month} buttonName={generatePayload(sortedTimes).length + " Datensätze Exportieren"}/>
        </Box>
    )
}

export default TaskHourExport