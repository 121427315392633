import {useMutation} from '@apollo/client'
import {CREATE_MESSAGE} from "../mutations/createMessage";

export const useMessages = () => {

    const [add, {error: errorAdd}] = useMutation(
        CREATE_MESSAGE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                console.log('message created!')
            },
        },
    );

    return {
        error: errorAdd,
        addMessage: (message, chatid) => add({
            variables: {
                message: message,
                chatid: chatid
            }
        }),
    }
}