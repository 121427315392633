import {Route, Routes, useLocation} from "react-router-dom";
import Home from "../Home";
import Protocol from "../Protocol";
import Tasks from "../Tasks";
import Users from "../Users";
import CreateProtocol from "../Protocol/CreateProtocol";
import CreateTask from "../Tasks/CreateTask";
import PageTransition from "../../PageTransition";
import {AnimatePresence} from "framer-motion";
import Orders from "../Orders";
import CreateOrder from "../Orders/CreateOrder";
import ViewTask from "../Tasks/ViewTask";
import Todos from "../Todos";
import Feedback from "../Feedback";
import TimeHistory from "../Time/History/TimeHistory";
import Files from "../Files";

const ContentRouter = () => {
    const location = useLocation();
    return (
        <AnimatePresence
            mode={'wait'}
            initial={false}
            onExitComplete={() => window.scrollTo(0, 0)}
        >
            <Routes location={location} key={location.pathname}>
                <Route index element={<PageTransition><Home/></PageTransition>}/>
                <Route path='tasks'>
                    <Route index element={<PageTransition><Tasks/></PageTransition>}/>
                    <Route path='create' element={<PageTransition><CreateTask/></PageTransition>}/>
                    <Route path='current' element={<PageTransition><Tasks/></PageTransition>}/>
                    <Route path=':id' element={<PageTransition><ViewTask/></PageTransition>}/>
                    <Route path='edit/:taskId' element={<PageTransition><CreateTask/></PageTransition>}/>
                </Route>
                <Route path='todos'>
                    <Route index element={<PageTransition><Todos/></PageTransition>}/>
                </Route>
                <Route path='protocol'>
                    <Route index element={<PageTransition><Protocol/></PageTransition>}/>
                    <Route path='create' element={<PageTransition><CreateProtocol/></PageTransition>}/>
                    <Route path='create/:id' element={<PageTransition><CreateProtocol/></PageTransition>}/>
                    <Route path=':id' element={<PageTransition><Protocol/></PageTransition>}/>
                    <Route path=':id/edit' element={<PageTransition><Protocol/></PageTransition>}/>
                </Route>
                <Route path='times'>
                    <Route index element={<PageTransition><TimeHistory/></PageTransition>}/>
                    <Route path=':section' element={<PageTransition><TimeHistory/></PageTransition>}/>
                </Route>
                <Route path='users'>
                    <Route index element={<PageTransition><Users/></PageTransition>}/>
                    <Route path='create' element={<PageTransition><Users/></PageTransition>}/>
                    <Route path=':id' element={<PageTransition><Users/></PageTransition>}/>
                    <Route path=':id/edit' element={<PageTransition><Users/></PageTransition>}/>
                </Route>
                <Route path='orders'>
                    <Route index element={<PageTransition><Orders/></PageTransition>}/>
                    <Route path='create' element={<PageTransition><CreateOrder/></PageTransition>}/>
                </Route>
                <Route path='feedback'>
                    <Route index element={<PageTransition><Feedback/></PageTransition>}/>
                </Route>
                <Route path='files'>
                    <Route index element={<PageTransition><Files allFiles/></PageTransition>}/>
                </Route>
            </Routes>
        </AnimatePresence>
    )
}

export default ContentRouter