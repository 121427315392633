import {
    Box,
    Card,
    CardContent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import moment from "moment/moment";
import OrderState from "./OrderState";

const OrderHistory = (props) => {

    const renderOrder = () => {

        let orderItems = props.orders.filter(order => order?.task._id === props.task)

        if (orderItems.length === 0) {
            return (
                <Card>
                    <CardContent>
                        <Box p={1} textAlign={'center'}>
                            <Typography variant={'body2'}>Keine Bestellungen vorhanden.</Typography>
                        </Box>
                    </CardContent>
                </Card>
            )
        }

        return (
            <Card>
                <CardContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant={'h7'}>
                                        Bestellung
                                    </Typography>
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Typography variant={'h7'}>
                                        Status
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderItems.map((order, index) => (
                                <TableRow
                                    key={index}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        <Stack direction={'column'}>
                                            <Typography variant={'body2'}>
                                                {moment(order.created_at).format('LLLL')}
                                            </Typography>
                                            <Typography variant={'body2'}>
                                                {order.user.prename} {order.user.surname}
                                            </Typography>
                                            {order.order}
                                        </Stack>
                                    </TableCell>
                                    <TableCell align={'right'}><OrderState
                                        state={order.state}/></TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        )
    }

    return renderOrder()
}

export default OrderHistory