import { Fab } from "@mui/material";
import styles from "./navigation.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ImHome3 } from "react-icons/im";
import { AnimatePresence, motion } from "framer-motion";

const Navigation = () => {
    const router = useNavigate();
    const location = useLocation();

    return (
        <AnimatePresence>
            {location.pathname !== "/" && (
                <motion.div
                    className={styles.fabContainer}
                    initial={{
                        opacity: 0,
                        y: 100,
                        x: "-50%",
                        scale: 0.8,
                        rotateX: -45,
                    }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        x: "-50%",
                        scale: 1,
                        rotateX: 0,
                    }}
                    exit={{
                        opacity: 0,
                        y: 100,
                        x: "-50%",
                        scale: 0.8,
                        rotateX: -45,
                    }}
                    whileHover={{
                        scale: 1.1,
                    }}
                    whileTap={{
                        scale: 1,
                    }}
                    transition={{
                        type: "spring",
                        stiffness: 100,
                        mass: 0.1,
                    }}
                >
                    <Fab
                        disabled={location.pathname === "/"}
                        onClick={() => router("/")}
                        color={"info"}
                        className={styles.fab}
                    >
                        <ImHome3 />
                    </Fab>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Navigation;