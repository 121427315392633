import {gql} from '@apollo/client';
import {USER_ENTITY} from "../entities/userEntity";

export const UPDATE_USER = gql`
  mutation updateUser($input: UserInput!, $_id: String!) {
    updateUser(input: $input, _id: $_id) {
        ...UserEntity
    }
  }
  ${USER_ENTITY}
`;