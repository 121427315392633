import {useOrders} from "../../apollo/hooks/useOrders";
import {Box, Button, Card, CardContent, Grid, Modal, Paper, Stack, Tab, Tabs, Typography} from "@mui/material";
import {useTasks} from "../../apollo/hooks/useTasks";
import OrderState from "./OrderState";
import moment from "moment";
import {useUsers} from "../../apollo/hooks/useUsers";
import {useState} from "react";
import FullpageLoader from "../common/FullPageLoader";
import {getBusinessDaysDelay, overDue, overDueExact} from "../../dateFunctions";
import FileUpload from "../FileUpload";
import {useGlobalStore} from "../../store";
import {useFiles} from "../../apollo/hooks/useFiles";
import {useNavigate} from "react-router-dom";
import Files from "../Files";
import User from "../common/User";

const Orders = () => {

    const router = useNavigate()

    const selectedFile = useGlobalStore((state) => state.selectedFile)
    const clearSelectedFile = useGlobalStore((state) => state.clearSelectedFile)

    const [tabValue, setTabValue] = useState("BESTELLT")
    const {updateOrder, removeOrder, updateOrderState, orders, loading: ordersLoading, error: ordersError} = useOrders()
    const {loading: tasksLoading, error: tasksError} = useTasks()
    const {me, loading: usersLoading, error: usersError} = useUsers()
    const {uploadFile, loading: filesLoading, error: filesError} = useFiles()

    const [openModal, setOpenModal] = useState(false)
    const [order, setOrder] = useState(undefined)

    if (ordersLoading || tasksLoading || usersLoading || filesLoading) return <FullpageLoader/>
    if (ordersError || tasksError || usersError || filesError) return <FullpageLoader error/>

    const handleChangeTab = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const handleUpdateOrder = (order, newState) => {
        const payload = {
            user: order.user._id,
            responsible: newState === "BEARBEITET" ? me.me._id : order.responsible?._id ? order.responsible?._id : null,
            task: order.task._id,
            order: order.order,
            requestdate: order.requestdate,
            state: newState
        }
        updateOrder(payload, order._id)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10px',
    };

    const handleOpenBestaetigung = (order) => {
        setOpenModal(true)
        setOrder(order)
    }

    const handleBestaetigung = () => {
        const payload = {
            state: "LIEFERBESTAETIGUNG"
        }
        updateOrderState(payload, order._id).then((res) => {
            selectedFile.map((file) => {
                const inputPayload = {
                    filename: file.name,
                    permission: file.permission,
                    mimetype: file.type,
                    order: order._id,
                    user: me.me._id
                }
                uploadFile(inputPayload, file).then(() => {
                    clearSelectedFile()
                    setOpenModal(false)
                    router('/orders')
                })
            })
        })
    }

    const renderOrderStateButton = (order) => {
        switch (order.state) {
            case "BESTELLT":
                return (
                    <Button disabled={me.me.role === "MITARBEITER"}
                            onClick={() => handleUpdateOrder(order, "BEARBEITET")}
                            color={'primary'} size={'small'}>
                        Als bearbeitet markieren
                    </Button>
                )
            case "BEARBEITET":
                return (
                    <Button disabled={me.me.role !== "MITARBEITER"}
                            onClick={() => handleOpenBestaetigung(order)} color={'primary'}
                            size={'small'}>
                        Lieferung bestätigen
                    </Button>
                )
            case "LIEFERBESTAETIGUNG":
                return (
                    <Button disabled={me.me.role === "MITARBEITER"}
                            onClick={() => handleUpdateOrder(order, "COMPLETED")} color={'primary'}
                            size={'small'}>
                        Bestellung abschließen
                    </Button>
                )
            case "STORNIERT":
                return (
                    <Button disabled={me.me.role === "MITARBEITER"}
                            onClick={() => handleUpdateOrder(order, "COMPLETED")} color={'primary'}
                            size={'small'}>
                        Bestellung abschließen
                    </Button>
                )
            default:
                return "Fehler"
        }
    }


    let sortedOrders = orders.reduce(function (o, cur) {
        if (!o) return o
        var occurs = o.find(ob => ob.customer === cur.task.customer);
        if (occurs) {
            occurs.orders.push(cur)
        } else {
            if ([cur].filter(c => c.state === tabValue).length === 0) return o
            var obj = {
                customer: cur.task.customer,
                orders: [cur]
            };
            o = o.concat(obj);
        }
        return o;
    }, [])

    const renderCustomers = () => {
        let filteredOrders = []
        if( me.me.role === "MITARBEITER") {
            filteredOrders = sortedOrders.filter(order => order.orders.find(o => o.user._id === me.me._id))
        } else {
            filteredOrders = sortedOrders
        }
        return filteredOrders.map((order, index) => (
            <Stack key={index} direction={'column'} spacing={1}>
                <Paper>
                    <Box p={1} textAlign={'center'}>
                        <Typography variant={'body2'}>{order.customer}</Typography>
                    </Box>
                </Paper>
                <Stack spacing={1} direction={'column'}>
                    {renderOrders(order.orders)}
                </Stack>
            </Stack>
        ))
    }

    const renderOrders = (array) => {
        return array
            .filter(order => order.state === tabValue)
            .filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true)
            .map((order, index) => (
                <Paper key={index}>
                    <Stack direction={'column'} spacing={1} p={1}>
                        <Stack direction={'row'} spacing={2}
                               alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction={'column'} p={1}>
                                <Typography
                                    variant={'body2'}>Bestellzeitpunkt</Typography>
                                <Typography
                                    variant={'h7'}>{moment(order.created_at).format('LLL')}</Typography>
                            </Stack>
                            <Stack direction={'column'} p={1}>
                                <Typography
                                    variant={'body2'}>Wunsch Liefertermin</Typography>
                                <Typography
                                    color={overDue(order.requestdate) ? "secondary" : "#000000"}
                                    variant={'h7'}>{moment(order.requestdate).format('LL')}</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                            {order.state === "LIEFERBESTAETIGUNG" &&
                                <Stack direction={'column'} px={1}>
                                    <Typography
                                        variant={'body2'}>Bestätigt am</Typography>
                                    <Typography
                                        variant={'h7'}>{moment(order.updated_at).format('LLL')}</Typography>
                                </Stack>}
                            {order.state === "BEARBEITET" &&
                                <Stack direction={'column'} px={1}>
                                    <Typography
                                        variant={'body2'}>Bearbeitet am</Typography>
                                    <Typography
                                        variant={'h7'}>{moment(order.updated_at).format('LLL')}</Typography>
                                </Stack>}
                            {order.state === "STORNIERT" &&
                                <Stack direction={'column'} px={1}>
                                    <Typography
                                        variant={'body2'}>Storniert am</Typography>
                                    <Typography
                                        variant={'h7'}>{moment(order.updated_at).format('LLL')}</Typography>
                                </Stack>}
                            {order.state !== "STORNIERT" && overDueExact(order.requestdate) &&
                                <Stack direction={'column'} px={1}>
                                    <Typography
                                        variant={'body2'}>Verzugstage</Typography>
                                    <Typography
                                        variant={'h7'}>{getBusinessDaysDelay(order.requestdate, moment())}</Typography>
                                </Stack>}
                        </Stack>
                        <Box>
                            <Grid item xs={12}>
                                <Stack direction={'row'} spacing={1}>
                                    <Stack direction={'column'} spacing={1}>
                                        <Typography variant={'body2'}>Status</Typography>
                                        <OrderState state={order.state}/>
                                    </Stack>
                                    <Stack direction={'column'} spacing={1}>
                                        <Typography variant={'body2'}>Besteller</Typography>
                                        <User userId={order.user._id}/>
                                    </Stack>
                                    {order?.responsible &&
                                        <Stack direction={'column'} spacing={1}>
                                            <Typography variant={'body2'}>Verantwortlich</Typography>
                                            <User color={'primary'} userId={order.responsible._id}/>
                                        </Stack>}
                                </Stack>
                            </Grid>
                        </Box>
                        <Stack direction={'column'} spacing={1}>
                            <Card>
                                <CardContent>
                                    <Box p={2}>
                                        <Stack direction={'column'}>
                                            <Typography variant={'body2'}>
                                                Bestellung
                                            </Typography>
                                            <Typography
                                                variant={'body1'}>{order.order}</Typography>
                                        </Stack>
                                    </Box>
                                </CardContent>
                            </Card>
                            {order.state === "LIEFERBESTAETIGUNG" &&
                                <Files id={order._id}/>
                            }
                        </Stack>
                        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                            {renderOrderStateButton(order)}
                            <Stack direction={'row'} spacing={1}>
                                <Button
                                    disabled={order.state === "LIEFERBESTAETIGUNG" || order.state === "STORNIERT"}
                                    onClick={() => handleUpdateOrder(order, "STORNIERT")} color={'secondary'}
                                    size={'small'}>
                                    Stornieren
                                </Button>
                                {me.me.role === "ADMIN" &&
                                    <Button onClick={() => removeOrder(order._id)}
                                            color={'secondary'}
                                            size={'small'}>
                                        Löschen
                                    </Button>}
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            ))
    }

    return (
        <Stack direction={'column'} spacing={1}>
            <Paper>
                <Box display={'flex'} justifyContent={'center'}>
                    <Tabs
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        variant="scrollable"
                        color="primary"
                        value={tabValue}
                        onChange={handleChangeTab}
                    >
                        <Tab value="BESTELLT" label={
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant={'h7'}>
                                    {orders.filter(order => order.state === "BESTELLT").filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true).length}
                                </Typography>
                                <Typography variant={'body1'}>
                                    Offen
                                </Typography>
                            </Stack>
                        }/>
                        <Tab value="BEARBEITET" label={
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant={'h7'}>
                                    {orders.filter(order => order.state === "BEARBEITET").filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true).length}
                                </Typography>
                                <Typography variant={'body1'}>
                                    Bearbeitet
                                </Typography>
                            </Stack>
                        }/>
                        <Tab value="LIEFERBESTAETIGUNG" label={
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant={'h7'}>
                                    {orders.filter(order => order.state === "LIEFERBESTAETIGUNG").filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true).length}
                                </Typography>
                                <Typography variant={'body1'}>
                                    Lieferbestätigung
                                </Typography>
                            </Stack>
                        }/>
                        <Tab value="STORNIERT" label={
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography variant={'h7'}>
                                    {orders.filter(order => order.state === "STORNIERT").filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true).length}
                                </Typography>
                                <Typography variant={'body1'}>
                                    Storniert
                                </Typography>
                            </Stack>
                        }/>
                    </Tabs>
                </Box>
                <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <Stack direction={'column'} spacing={1} p={1} sx={{...style, width: 400}}>
                        <FileUpload maxFiles={5} disablePermissions/>
                        <Box>
                            <Button disabled={selectedFile.length === 0} onClick={() => handleBestaetigung()}>Lieferung
                                bestätigen</Button>
                        </Box>
                    </Stack>
                </Modal>
            </Paper>
            {renderCustomers()}
        </Stack>
    )
}

export default Orders