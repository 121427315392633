import {gql} from '@apollo/client';
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {TASK_ENTITY} from "../entities/taskEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const UPDATE_PROTOCOL = gql`
  mutation updateProtocol($input: ProtocolInput!, $_id: String!) {
    updateProtocol(input: $input, _id: $_id) {
        ...ProtocolEntity
        task {
          ...TaskEntity
        }
        user {
          ...UserEntity
        }
    }
  }
  ${PROTOCOL_ENTITY},${TASK_ENTITY},${USER_ENTITY}
`;