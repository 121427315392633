import {Chip} from "@mui/material";

const OrderState = (props) => {
    switch (props.state) {
        case "BESTELLT":
            return (
                <Chip sx={{backgroundColor: '#e18e1a'}} color={'primary'} label={'Offen'}/>
            )
        case "BEARBEITET":
            return (
                <Chip color={'primary'} label={'Bearbeitet'}/>
            )
        case "LIEFERBESTAETIGUNG":
            return (
                <Chip sx={{backgroundColor: '#419a1d'}} color={'primary'} label={'Lieferbestätigung'}/>
            )
        case "COMPLETED":
            return (
                <Chip sx={{backgroundColor: '#419a1d'}} color={'primary'} label={'Abgeschlossen'}/>
            )
        case "STORNIERT":
            return (
                <Chip color={'secondary'} label={'Storniert'}/>
            )
        default:
            return "Fehler"
    }
}

export default OrderState