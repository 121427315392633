import {useUsers} from "../../../apollo/hooks/useUsers";
import FullpageLoader from "../FullPageLoader";
import {useEffect, useState} from "react";
import {BsFillPersonFill} from "react-icons/bs";
import {Chip, Stack, Typography} from "@mui/material";

const User = (props) => {

    const {users, loading: usersLoading, error: usersError} = useUsers()
    const [user, setUser] = useState([])

    useEffect(() => {
        setUser(users.find(user => user._id === props.userId))
    }, [props.userId, users]);

    if (usersLoading || !user) return <Chip icon={<BsFillPersonFill/>} label={"Lade Daten..."}/>
    if (usersError) return <FullpageLoader error position={'relative'}/>

    return props.type === "text" ?
        <Stack direction={'column'}>
            <Typography variant={'body2'}>Mitarbeiter</Typography>
            <Typography variant={'h7'}>{user.prename + " " + user.surname}</Typography>
        </Stack>
        :
        <Chip variant={'outlined'} color={props.color ? props.color : "default"} icon={<BsFillPersonFill/>} label={user.prename + " " + user.surname}/>
}

export default User