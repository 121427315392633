// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_navButton__swzii {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
}
.home_iconWrapper__uJmTW {
    z-index: 0;
    position: relative;
    border-radius: 50%;
    width: 60px;
    height: 60px;
}
.home_categoryWrapper__1Sb0h {
    border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/views/Home/home.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,iDAAiD;IACjD,eAAe;AACnB;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".navButton {\n    width: 100%;\n    background-color: #ffffff;\n    border-radius: 10px;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n    cursor: pointer;\n}\n.iconWrapper {\n    z-index: 0;\n    position: relative;\n    border-radius: 50%;\n    width: 60px;\n    height: 60px;\n}\n.categoryWrapper {\n    border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navButton": `home_navButton__swzii`,
	"iconWrapper": `home_iconWrapper__uJmTW`,
	"categoryWrapper": `home_categoryWrapper__1Sb0h`
};
export default ___CSS_LOADER_EXPORT___;
