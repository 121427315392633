import {useUsers} from "../../../apollo/hooks/useUsers";
import FullpageLoader from "../FullPageLoader";
import {useEffect, useState} from "react";
import {BsFillPersonFill} from "react-icons/bs";
import {Chip, Stack, Typography} from "@mui/material";
import {useTasks} from "../../../apollo/hooks/useTasks";
import moment from "moment";
import {BiTask} from "react-icons/bi";

const Customer = (props) => {

    const {tasks, loading: tasksLoading, error: tasksError} = useTasks()
    const [task, setTask] = useState([])

    useEffect(() => {
        setTask(tasks.find(task => task._id === props.taskId))
    }, [props.taskId, tasks]);

    if (tasksLoading) return <Chip icon={<BiTask/>} label={"Lade Daten..."}/>
    if (tasksError) return <FullpageLoader error position={'relative'}/>

    if (!task) return  props.type === "text" ?
        <Stack direction={'column'}>
            <Typography variant={'body2'}>{"Entfernter Auftrag"}</Typography>
            <Typography variant={'h7'}>---</Typography>
        </Stack>
        :
        <Chip icon={<BiTask/>} label={"Auftrag entfernt"}/>

    return props.type === "text" ?
        <Stack direction={'column'}>
            <Typography variant={'body2'}>{"Auftrag (" + moment(task.startdate).format("L") + " - " + moment(task.enddate).format("L") + ")"}</Typography>
            <Typography variant={'h7'}>{task.customer}</Typography>
        </Stack>
        :
        <Chip variant={'outlined'} color={props.color ? props.color : "default"} icon={<BsFillPersonFill/>} label={task.customer}/>
}

export default Customer