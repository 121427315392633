import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {AGENDA_ENTITY} from "../entities/agendaEntity";

export const ON_AGENDAS_CHANGED = gql`
  subscription {
    onAgendasChanged {
        ...AgendaEntity
        task {
          ...TaskEntity
        }
    }
  }
  ${AGENDA_ENTITY},${TASK_ENTITY}
`;