import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_PROTOCOLS} from "../queries/getAllProtocols";
import {ON_PROTOCOLS_CHANGED} from "../subscriptions/onProtocolsChanged";
import {CREATE_PROTOCOL} from "../mutations/createProtocol";
import {UPDATE_PROTOCOL} from "../mutations/updateProtocol";
import {REMOVE_PROTOCOL} from "../mutations/removeProtocol";
import {GET_PROTOCOL_BY_ID} from "../queries/getProtocolById";
import {useUsers} from "./useUsers";

export const useProtocols = () => {

    const {me} = useUsers()
    const fetchQuery = GET_ALL_PROTOCOLS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {data: allProtocolsSubData} = useSubscription(ON_PROTOCOLS_CHANGED, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    });

    let protocolData = allProtocolsSubData ? allProtocolsSubData?.onProtocolsChanged : dataQuery ? dataQuery?.getAllProtocols : [];

    //console.log("ProtocolQuery", allProtocolsSubData?.onProtocolsChanged, dataQuery?.getAllProtocols)

    const [add, {error: errorAdd, loading: loadingAdd}] = useMutation(
        CREATE_PROTOCOL,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('protocol created!')
            },
        },
    );

    const [update, {error: errorUpdate, loading: loadingUpdate}] = useMutation(
        UPDATE_PROTOCOL,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('protocol updated!')
            },
        },
    );

    const [remove, {error: errorRemove, loading: loadingRemove}] = useMutation(
        REMOVE_PROTOCOL,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('protocol removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_PROTOCOL_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                //refetchQuery()
                console.log('protocol found!')
            },
        },
    );

    return {
        protocols: protocolData,
        myProtocols: protocolData?.filter(protocol => protocol.user._id === me?.me._id),
        loading: loadingQuery || loadingAdd || loadingUpdate || loadingRemove,
        error: errorQuery || errorAdd || errorRemove || errorUpdate,
        addProtocol: (input) => add({
            variables: {
                input: input
            }
        }),
        updateProtocol: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeProtocol: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getProtocolById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}