import {gql} from '@apollo/client';
import {USER_ENTITY} from "../entities/userEntity";
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {ORDER_ENTITY} from "../entities/orderEntity";
import {TIME_ENTITY} from "../entities/timeEntity";
import {FILE_ENTITY} from "../entities/fileEntity";

export const GET_USER_BY_ID = gql`
  query getUserById($_id: String!){
    getUserById(_id: $_id) {
        ...UserEntity
        protocols {
          ...ProtocolEntity
        }
        orders {
          ...OrderEntity
        }
        files {
          ...FileEntity
        }
        times {
          ...TimeEntity
        }
    }
  }
  ${USER_ENTITY},${PROTOCOL_ENTITY},${ORDER_ENTITY},${TIME_ENTITY},${FILE_ENTITY}
`;