import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_AGENDAS} from "../queries/getAllAgendas";
import {ON_AGENDAS_CHANGED} from "../subscriptions/onAgendasChanged";
import {CREATE_AGENDA} from "../mutations/createAgenda";
import {UPDATE_AGENDA} from "../mutations/updateAgenda";
import {REMOVE_AGENDA} from "../mutations/removeAgenda";

export const useAgendas = () => {

    const fetchQuery = GET_ALL_AGENDAS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {data: allAgendasSubData} = useSubscription(ON_AGENDAS_CHANGED, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    });

    let agendaData = allAgendasSubData ? allAgendasSubData?.onAgendasChanged : dataQuery ? dataQuery?.getAllAgendas : [];

    //console.log("AgendaQuery", allAgendasSubData?.onAgendasChanged, dataQuery?.getAllAgendas)

    const [add, {error: errorAdd}] = useMutation(
        CREATE_AGENDA,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('agenda created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_AGENDA,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('agenda updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_AGENDA,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('agenda removed!')
            },
        },
    );

    return {
        agendas: agendaData,
        loading: loadingQuery,
        error: errorQuery || errorAdd || errorRemove || errorUpdate,
        addAgenda: (input) => add({
            variables: {
                input: input
            }
        }),
        updateAgenda: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeAgenda: (id) => remove({
            variables: {
                _id: id
            }
        })
    }
}