import moment from "moment/moment";
import {getDurationInSecondsInt} from "../../../dateFunctions";
import {Box} from "@mui/material";
import ExcelExport from "../../common/ExcelExport";

const EmployeeHourExport = (props) => {

    let sortedTimes = props.times.reduce(function (o, cur) {
        if (!o) return o
        var occurs = []
        if (moment(cur.starttime).format('MMMM YYYY') === props.month) {
            occurs = o.find(ob => (ob.user._id === cur.user._id) && moment(ob.day).isSame(moment(cur.starttime), 'day'));
        }

        if (occurs) {
            occurs.times.push(cur)
            if (cur.endtime) {
                if (cur.type === "CHECKINCHECKOUT") {
                    occurs.worktime = occurs.worktime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
                if (cur.type === "BREAK") {
                    occurs.breaktime = occurs.breaktime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
                if (cur.type === "DRIVE") {
                    occurs.drivetime = occurs.drivetime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
                if (cur.type === "LAGER") {
                    occurs.lagertime = occurs.lagertime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
                if (cur.type === "HAENDLER") {
                    occurs.haendlertime = occurs.haendlertime + getDurationInSecondsInt(cur.starttime, cur.endtime)
                }
            }
        } else {
            var obj = {
                day: cur.starttime,
                user: cur.user,
                start: cur.starttime,
                worktime: cur.endtime && cur.type === "CHECKINCHECKOUT" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                breaktime: cur.endtime && cur.type === "BREAK" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                drivetime: cur.endtime && cur.type === "DRIVE" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                lagertime: cur.endtime && cur.type === "LAGER" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                haendlertime: cur.endtime && cur.type === "HAENDLER" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                times: [cur]
            };
            o = o.concat(obj);
        }
        return o;
    }, [])

    const generatePayload = (times) => {

        const merge = (a, b, predicate = (a, b) => a === b) => {
            const c = [...a];
            b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
            return c;
        }

        let placeholderMonth = []
        let payload = []
        for (var i = 0; i < moment(props.month, 'MMMM YYYY').daysInMonth(); i++) {
            placeholderMonth.push({
                Tag: moment(props.month, 'MMMM YYYY').startOf('month').add(i, 'days').date() + ".",
                Wochentag: moment(props.month, 'MMMM YYYY').startOf('month').add(i, 'days').format('dddd'),
                von: '',
                bis: '',
                Gesamt: 0,
                Pause: 0,
                Brutto: 0,
            })
        }

        times.map(time => {
            payload.push({
                Tag: moment(time.day).date() + ".",
                Wochentag: moment(time.day).format('dddd'),
                von: moment(time.start).format('HH:mm'),
                bis: moment(time.times.slice(-1)[0]?.endtime).format('HH:mm'),
                Gesamt: moment.duration((time.worktime + time.breaktime + time.drivetime + time.lagertime + time.haendlertime), "seconds").format("hh:mm", {
                    trim: false
                }),
                Pause: moment.duration((time.breaktime), "seconds").format("hh:mm", {
                    trim: false
                }),
                Brutto: moment.duration((time.worktime + time.drivetime + time.lagertime + time.haendlertime), "seconds").format("hh:mm", {
                    trim: false
                })
            })
        })

        return merge(payload, placeholderMonth, (a, b) => a.Tag === b.Tag).sort((a, b) => a.Tag - b.Tag);
    }

    return (
        <Box>
            <ExcelExport
                headline={`Stundenerfassung ${props.month}\n\r${props.employee.prename} ${props.employee.surname}`}
                excelData={generatePayload(sortedTimes)} buttonName={"Stundenerfassung Exportieren"}
                fileName={`Stundenerfassung ${props.employee.prename} ${props.employee.surname} ${props.month}`}/>
        </Box>
    )
}

export default EmployeeHourExport