import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {ORDER_ENTITY} from "../entities/orderEntity";
import {FILE_ENTITY} from "../entities/fileEntity";
import {TIME_ENTITY} from "../entities/timeEntity";

export const GET_ALL_TASKS = gql`
  query getAllTasks {
    getAllTasks {
        ...TaskEntity
        protocols {
          ...ProtocolEntity
        }
        orders {
          ...OrderEntity
        }
        files {
          ...FileEntity
        }
        times {
          ...TimeEntity
        }
    }
  }
  ${TASK_ENTITY},${PROTOCOL_ENTITY},${ORDER_ENTITY},${FILE_ENTITY},${TIME_ENTITY}
`;