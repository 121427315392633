export const PermissionType = (props) => {
    switch(props.type) {
        case "ALL":
            return "Alle"
        case "MITARBEITER":
            return "Mitarbeiter"
        case "KOORDINATOR":
            return "Koordinator"
        case "BAULEITER":
            return "Bauleiter"
        case "ADMIN":
            return "Admin"
        default:
            return "Unbekannter Typ."
    }
}