import {useOrders} from "../../apollo/hooks/useOrders";
import FullPageLoader from "../common/FullPageLoader";
import {Alert, Box, Button, Paper, Stack, Typography} from "@mui/material";
import {overDue} from "../../dateFunctions";
import {useTasks} from "../../apollo/hooks/useTasks";
import {useNavigate} from "react-router-dom";
import Agenda from "../Agenda";
import moment from "moment";
import {useUsers} from "../../apollo/hooks/useUsers";
import {useProtocols} from "../../apollo/hooks/useProtocols";

const Todos = () => {

    const router = useNavigate()

    const {orders, loading: ordersLoading, error: ordersError} = useOrders()
    const {tasks, myTasks, loading: tasksLoading, error: tasksError} = useTasks()
    const {protocols, loading: protocolsLoading, error: protocolsError} = useProtocols()
    const {me, loading: usersLoading, error: usersError} = useUsers()

    if (ordersLoading || tasksLoading || usersLoading || protocolsLoading) return <FullPageLoader
        position={'absolute'}/>
    if (ordersError || tasksError || usersError || protocolsError) return <FullPageLoader error position={'absolute'}/>

    const renderOpenOrders = () => {
        let openOrdersCount = orders.filter(order => order.order_state === "BESTELLT").length
        if (openOrdersCount === 0) return (
            <Alert
                severity="success">Keine offenen Bestellungen.</Alert>
        )
        return (
            <Alert
                action={
                    <Button onClick={() => router("/orders")} color="inherit" size="small">
                        Bestellungen
                    </Button>
                }
                severity="warning">{openOrdersCount} offene {openOrdersCount === 1 ? "Bestellung" : "Bestellungen"}.</Alert>
        )
    }

    const renderDueOrders = () => {
        let dueOrdersCount = orders.filter(order => overDue(order.requestdate)).length
        if (dueOrdersCount === 0) return (
            <Alert
                severity="success">Keine überfälligen Bestellungen.</Alert>
        )
        return (
            <Alert
                action={
                    <Button onClick={() => router("/orders")} color="inherit" size="small">
                        Bestellungen
                    </Button>
                }
                severity="error">{dueOrdersCount} überfällige {dueOrdersCount === 1 ? "Bestellung" : "Bestellungen"}.</Alert>
        )
    }

    const renderDueTasks = () => {
        let dueTasksCount = tasks.filter(task => overDue(task.enddate)).length
        if (dueTasksCount === 0) return (
            <Alert
                severity="success">Keine überfälligen Aufträge.</Alert>
        )
        return (
            <Alert
                action={
                    <Button onClick={() => router("/tasks")} color="inherit" size="small">
                        Aufträge
                    </Button>
                }
                severity="error">{dueTasksCount} {dueTasksCount === 1 ? "überfälliger Auftrag" : "überfällige Aufträge"}.</Alert>
        )
    }

    const renderAgenda = () => {
        return (
            <Stack direction={'column'} spacing={1}>
                {myTasks.filter(task => task.state === "ENABLED").map((task, index) => (
                    <Stack direction={'column'} spacing={1}>
                        <Typography variant={'body1'}>
                            {task.customer}
                        </Typography>
                        <Agenda key={index} noEdit showCustomer taskId={task._id}/>
                    </Stack>
                ))}
            </Stack>
        )
    }

    const renderDueProtocols = () => {
        let dueProtocolsCount = myTasks.length - protocols.filter(protocol => protocol.user._id === me.me._id && moment().startOf('day').isSame(moment(protocol.created_at).startOf('day'))).length
        if (dueProtocolsCount === 0) return (
            <Alert
                severity="success">Keine überfälligen Tagesberichte.</Alert>
        )
        return (
            <Alert
                action={
                    <Button onClick={() => router("/protocol/create")} color="inherit" size="small">
                        Tagesberichte
                    </Button>
                }
                severity="error">{dueProtocolsCount} {dueProtocolsCount === 1 ? "Tagesbericht" : "Tagesberichte"} erforderlich.</Alert>
        )
    }

    const todoCategorys = [
        {
            category: "Bestellungen",
            alerts: [renderOpenOrders(), renderDueOrders()],
            permissions: ['ADMIN', 'KOORDINATOR']
        },
        {
            category: "Aufträge",
            alerts: [renderDueTasks()],
            permissions: ['ADMIN', 'KOORDINATOR']
        },
        {
            category: "Tagesberichte",
            alerts: [renderDueProtocols()],
            permissions: ['ADMIN', 'MITARBEITER']
        },
        {
            category: "Agenden",
            alerts: [renderAgenda()],
            permissions: ['ADMIN', 'MITARBEITER']
        }
    ]

    const renderAlerts = (alerts) => {
        return alerts.map((alert, index) => (
            <Box key={index}>
                {alert}
            </Box>
        ))
    }

    return (
        <Stack direction={'column'} spacing={1}>
            {todoCategorys.filter(category => category.permissions.includes(me.me.role)).map((category, index) => (
                <Stack key={index} direction={'column'} spacing={1}>
                    <Typography variant={'h6'}>{category.category}</Typography>
                    <Paper>
                        <Box p={1}>
                            <Stack direction={'column'} spacing={1}>
                                {renderAlerts(category.alerts)}
                            </Stack>
                        </Box>
                    </Paper>
                </Stack>
            ))}
        </Stack>
    )
}

export default Todos