import {gql} from '@apollo/client';
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {TASK_ENTITY} from "../entities/taskEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const GET_PROTOCOL_BY_ID = gql`
  query getProtocolById($_id: String!){
    getProtocolById(_id: $_id) {
        ...ProtocolEntity
        task {
          ...TaskEntity
        }
        user {
          ...UserEntity
        }
    }
  }
  ${PROTOCOL_ENTITY},${TASK_ENTITY},${USER_ENTITY}
`;