import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_ORDERS} from "../queries/getAllOrders";
import {ON_ORDERS_CHANGED} from "../subscriptions/onOrdersChanged";
import {CREATE_ORDER} from "../mutations/createOrder";
import {UPDATE_ORDER} from "../mutations/updateOrder";
import {REMOVE_ORDER} from "../mutations/removeOrder";
import {GET_ORDER_BY_ID} from "../queries/getOrderById";
import {GET_ORDER_STATES} from "../queries/getOrderStates";
import {useUsers} from "./useUsers";
import {UPDATE_ORDER_STATE} from "../mutations/updateOrderState";

export const useOrders = () => {

    const {me} = useUsers()
    const fetchQuery = GET_ALL_ORDERS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {
        data: orderStates,
        loading: loadingOrderStates,
        error: errorOrderStates,
    } = useQuery(GET_ORDER_STATES, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {data: allOrdersSubData} = useSubscription(ON_ORDERS_CHANGED, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    });

    let orderData = allOrdersSubData ? allOrdersSubData?.onOrdersChanged : dataQuery ? dataQuery?.getAllOrders : [];

    //console.log("OrderQuery", allOrdersSubData?.onOrdersChanged, dataQuery?.getAllOrders)

    const [add, {error: errorAdd, loading: loadingAdd}] = useMutation(
        CREATE_ORDER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('order created!')
            },
        },
    );

    const [update, {error: errorUpdate, loading: loadingUpdate}] = useMutation(
        UPDATE_ORDER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('order updated!')
            },
        },
    );

    const [updateState, {error: errorStateUpdate}] = useMutation(
        UPDATE_ORDER_STATE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('order state updated!')
            },
        },
    );

    const [remove, {error: errorRemove, loading: loadingRemove}] = useMutation(
        REMOVE_ORDER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('order removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_ORDER_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                //refetchQuery()
                console.log('order found!')
            },
        },
    );

    return {
        orders: orderData,
        myOrders: orderData?.filter(order => order.user._id === me?.me._id),
        orderStates: orderStates,
        loading: loadingQuery || loadingOrderStates || loadingAdd || loadingUpdate || loadingRemove,
        error: errorQuery || errorAdd || errorRemove || errorUpdate || errorOrderStates,
        addOrder: (input) => add({
            variables: {
                input: input
            }
        }),
        updateOrder: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        updateOrderState: (input, id) => updateState({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeOrder: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getOrderById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}