import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {TIME_ENTITY} from "../entities/timeEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const ON_TIMES_CHANGED = gql`
  subscription {
    onTimesChanged {
        ...TimeEntity
        task {
          ...TaskEntity
        }
        user {
          ...UserEntity
        }
    }
  }
  ${TIME_ENTITY},${TASK_ENTITY},${USER_ENTITY}
`;