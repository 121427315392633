import {gql} from '@apollo/client';
import {ORDER_ENTITY} from "../entities/orderEntity";

export const UPDATE_ORDER_STATE = gql`
  mutation updateOrderState($input: OrderStateInput!, $_id: String!) {
    updateOrderState(input: $input, _id: $_id) {
        ...OrderEntity
    }
  }
  ${ORDER_ENTITY}
`;