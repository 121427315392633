import {useLocation, useNavigate} from "react-router-dom";
import {useTasks} from "../../apollo/hooks/useTasks";
import {useUsers} from "../../apollo/hooks/useUsers";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {HiArrowLongRight} from "react-icons/hi2";
import moment from "moment/moment";
import FullpageLoader from "../common/FullPageLoader";
import {getBusinessDaysDelay, overDue, overDueExact} from "../../dateFunctions";
import {useState} from "react";
import User from "../common/User";

const ViewTask = () => {

    const router = useNavigate()
    const location = useLocation()
    const {tasks, myFutureTasks, loading: tasksLoading, error: tasksError} = useTasks()
    const {users, loading: usersLoading, error: usersError} = useUsers()

    const [taskTypeFilter, setTaskTypeFilter] = useState("NEUAUFTRAG");
    const [taskTimeFilter, setTaskTimeFilter] = useState("CURRENT");
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    if (usersLoading || tasksLoading) return <FullpageLoader position={'relative'}/>
    if (usersError || tasksError) return <FullpageLoader error position={'relative'}/>

    const handleSelectEmployee = (event, index) => {
        if (index) {
            setSelectedEmployee(users.find(x => x._id === index._id));
        } else {
            setSelectedEmployee(null)
        }
    }

    const handleSelectCustomer = (event, index) => {
        if (index) {
            setSelectedCustomer(tasks.find(x => x.customer === index.customer));
        } else {
            setSelectedCustomer(null)
        }
    }

    const renderTasks = () => {
        let taskArray = []
        if (location.pathname === '/tasks/current') {
            taskArray = myFutureTasks.filter(
                task => task.state === "ENABLED"
            )
        } else {
            taskArray = tasks
        }

        let filteredTasks = taskArray.filter(
            task => selectedCustomer ? task.customer === selectedCustomer.customer : true
        ).filter(
            task => selectedEmployee ? task.assigned.some(x => selectedEmployee && x === selectedEmployee._id) : true
        )

        if (filteredTasks.length === 0) {
            return (
                <Box my={1}>
                    <Paper>
                        <Box p={2} textAlign={'center'}>
                            <Typography variant={'body2'}>Keine Aufträge vorhanden.</Typography>
                        </Box>
                    </Paper>
                </Box>
            )
        }

        return filteredTasks
            .filter(task => task.type === taskTypeFilter)
            .filter(task => taskTimeFilter === "CURRENT" ? task.state === "ENABLED" : true)
            .filter(task => taskTimeFilter === "PAST" ? task.state === "FINISHED" : true)
            .map((task, index) => (
                <Stack key={index} spacing={0} direction={'column'}>
                    <Paper onClick={() => router('/tasks/' + task?._id)}>
                        <Stack direction={'column'} spacing={0}>
                            {!task.tos_accepted &&
                                <Alert severity={'error'}>Der Auftrag ist gesperrt, da die Widerrufsbelehrung noch nicht
                                    akzeptiert wurde.</Alert>}
                            <Box p={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Stack direction={'column'}>
                                            <Typography variant={'h6'}>{task.customer}</Typography>
                                            <Typography variant={'body2'}>{task.street + " " + task.number}</Typography>
                                            <Typography variant={'body2'}>{task.plz + " " + task.city}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6} display={'flex'}
                                          sx={{justifyContent: {xs: 'start', md: 'end'}}}>
                                        <Stack direction={'row'} spacing={1}
                                               alignItems={'center'} divider={<HiArrowLongRight/>}>
                                            <Stack direction={'column'}>
                                                <Typography
                                                    variant={'body2'}>Start</Typography>
                                                <Typography
                                                    variant={'h7'}>{moment(task.startdate).format('L')}</Typography>
                                            </Stack>
                                            <Stack direction={'column'}>
                                                <Typography
                                                    variant={'body2'}>Ende</Typography>
                                                <Typography
                                                    color={task.state !== "FINISHED" && overDue(task.enddate) ? "secondary" : "#000000"}
                                                    variant={'h7'}>{moment(task.enddate).format('L')}</Typography>
                                            </Stack>
                                            {task.state !== "FINISHED" && overDueExact(task.enddate) &&
                                                <Stack direction={'column'}>
                                                    <Typography
                                                        variant={'body2'}>Verzugstage</Typography>
                                                    <Typography
                                                        variant={'h7'}>{getBusinessDaysDelay(task.enddate, moment())}</Typography>
                                                </Stack>}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Card sx={{borderRadius: '0px'}}>
                                <CardContent>
                                    <Box p={1}>
                                        <Grid item xs={12}>
                                            <Stack direction={'column'} spacing={1}>
                                                <Stack direction={'row'} spacing={1}>
                                                    {task.assigned.map((user, index) => (
                                                        <User key={index} userId={user}/>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Stack>
                    </Paper>
                </Stack>

            ))
    }

    return (
        <Stack direction={'column'} spacing={1}>
            <Paper>
                <Box p={1}>
                    <Stack direction={'column'} spacing={1}>
                        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                            <Button fullWidth disabled={taskTimeFilter === "CURRENT"}
                                    onClick={() => setTaskTimeFilter("CURRENT")}>Aktiv</Button>
                            <Button fullWidth disabled={taskTimeFilter === "PAST"}
                                    onClick={() => setTaskTimeFilter("PAST")}>Abgeschlossen</Button>
                        </Stack>
                        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                            <Button fullWidth disabled={taskTypeFilter === "NEUAUFTRAG"}
                                    onClick={() => setTaskTypeFilter("NEUAUFTRAG")}>Neuaufträge</Button>
                            <Button fullWidth disabled={taskTypeFilter === "WARTUNG"}
                                    onClick={() => setTaskTypeFilter("WARTUNG")}>Wartungsaufträge</Button>
                            <Button fullWidth disabled={taskTypeFilter === "REPARATUR"}
                                    onClick={() => setTaskTypeFilter("REPARATUR")}>Reparaturaufträge</Button>
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                            <Autocomplete
                                id="customer"
                                onChange={handleSelectCustomer}
                                options={tasks}
                                fullWidth
                                getOptionLabel={option => option.customer}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={"Nach Kunden filtern"}
                                        variant="filled"
                                        size={"small"}
                                    />
                                )}
                            />
                            <Autocomplete
                                id="employee"
                                onChange={handleSelectEmployee}
                                options={users}
                                fullWidth
                                getOptionLabel={option => option.prename + " " + option.surname}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={"Nach Mitarbeiter filtern"}
                                        variant="filled"
                                        size={"small"}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
            {renderTasks()}
        </Stack>
    )
}

export default ViewTask