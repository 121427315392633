import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {ORDER_ENTITY} from "../entities/orderEntity";
import {USER_ENTITY} from "../entities/userEntity";
import {FILE_ENTITY} from "../entities/fileEntity";

export const ON_ORDERS_CHANGED = gql`
  subscription {
    onOrdersChanged {
        ...OrderEntity
        task {
          ...TaskEntity
        }
        user {
          ...UserEntity
        }
        responsible {
          ...UserEntity
        }
        files {
          ...FileEntity
        }
    }
  }
  ${ORDER_ENTITY},${TASK_ENTITY},${USER_ENTITY},${FILE_ENTITY}
`;