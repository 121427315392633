import {Box, Button, Card, CardContent, Grid, Paper, Stack, Typography} from "@mui/material";
import moment from "moment";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
import styles from './protocol.module.css'
import {containerElement, itemElement} from "../../stagger";
import {AnimatePresence, motion} from "framer-motion";
import {useProtocols} from "../../apollo/hooks/useProtocols";
import {useUsers} from "../../apollo/hooks/useUsers";
import Files from "../Files/Files";
import FullpageLoader from "../common/FullPageLoader";
import Agenda from "../Agenda";
import {BsCheckLg} from "react-icons/bs";
import TimeHistory from "../Time/History/TimeHistory";
import {useTasks} from "../../apollo/hooks/useTasks";
import User from "../common/User";
import EmployeeHistory from "../Time/History/EmployeeHistory";

const Protocol = (props) => {

    const {protocols, removeProtocol, loading: protocolsLoading, error: protocolsError} = useProtocols()
    const {me, loading: usersLoading, error: usersError} = useUsers()
    const {tasks, loading: tasksLoading, error: tasksError} = useTasks()

    if (protocolsLoading || usersLoading || tasksLoading) return <FullpageLoader position={'relative'}/>
    if (protocolsError || usersError || tasksError) return <FullpageLoader error position={'relative'}/>

    const selectedProtocols = protocols.filter(protocol => protocol?.task._id === props.taskId)

    const handleRemoveProtocol = (id) => {
        removeProtocol(id)
    }

    const renderProtocolEntries = () => {
        if (selectedProtocols.length === 0) {
            return (
                <motion.div
                    key={'empty'}
                    variants={itemElement}
                >
                    <Box my={1}>
                        <Paper>
                            <Box p={2} textAlign={'center'}>
                                <Typography variant={'body2'}>Keine Tagesberichte vorhanden.</Typography>
                            </Box>
                        </Paper>
                    </Box>
                </motion.div>
            )
        }

        return selectedProtocols.map((entry, index) => (
            <motion.div
                key={index}
                variants={itemElement}
            >
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary"/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Stack direction={'column'} spacing={0}>
                            <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                                <Box pt={1} p={1} className={styles.timestampWrapper}>
                                    <Typography
                                        variant={'body2'}>{moment(entry.created_at).format('LLL')}</Typography>
                                </Box>
                            </Stack>
                            <Paper className={styles.protocolWrapper}>
                                <Box p={1}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={12}>
                                            <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                                                <User userId={entry.user._id}/>
                                                <EmployeeHistory minifiedView user={entry.user} date={entry.created_at} task={tasks.find(task => task._id === props.taskId)}/>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Card>
                                                <CardContent>
                                                    <Box p={1}>
                                                        <Stack direction={'column'}>
                                                            <Typography
                                                                variant={'body2'}>Fortschritt</Typography>
                                                            <Typography
                                                                variant={'body1'}>{entry.progress}</Typography>
                                                        </Stack>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Agenda referenceDate={entry.updated_at} noEdit
                                                    user={entry.user._id}
                                                    taskId={entry?.task._id}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Files id={entry._id}/>
                                        </Grid>
                                        {me.me.role === "ADMIN" &&
                                            <Grid item xs={12}>
                                                <Stack direction={'row'} spacing={1} justifyContent={'end'}>
                                                    <Button color={'secondary'}
                                                            onClick={() => handleRemoveProtocol(entry._id)}>Löschen</Button>
                                                </Stack>
                                            </Grid>}
                                    </Grid>
                                </Box>
                            </Paper>
                        </Stack>
                    </TimelineContent>
                </TimelineItem>
            </motion.div>
        ))
    }

    return (
        <Stack direction={'column'} spacing={1}>
            <motion.div
                key={1}
                variants={containerElement}
                initial="hidden"
                animate="show"
                exit="hidden"
            >
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    <AnimatePresence mode={'wait'}>
                        {renderProtocolEntries()}
                    </AnimatePresence>
                </Timeline>
            </motion.div>
        </Stack>
    )
}

export default Protocol