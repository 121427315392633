import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {ORDER_ENTITY} from "../entities/orderEntity";

export const UPDATE_TASK_STATE = gql`
  mutation updateTaskState($input: TaskStateInput!, $_id: String!) {
    updateTaskState(input: $input, _id: $_id) {
        ...TaskEntity
        protocols {
          ...ProtocolEntity
        }
        orders {
          ...OrderEntity
        }
    }
  }
  ${TASK_ENTITY},${PROTOCOL_ENTITY},${ORDER_ENTITY}
`;