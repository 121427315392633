import {gql} from '@apollo/client';

export const TASK_ENTITY = gql`
  fragment TaskEntity on TaskEntity {
    _id,
    assigned,
    customer,
    ab,
    street,
    number,
    plz,
    city,
    long,
    lat,
    radius,
    startdate,
    enddate,
    tos_accepted,
    photo_permission,
    asked_for_review,
    type,
    state,
    created_at,
    updated_at
  }
`;