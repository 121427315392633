import {gql} from '@apollo/client';

export const ORDER_ENTITY = gql`
  fragment OrderEntity on OrderEntity {
    _id,
    order,
    requestdate,
    state,
    created_at,
    updated_at
  }
`;