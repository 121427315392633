export const containerElement = {
    hidden: {
        opacity: 0,
        transition: {staggerChildren: 0.02}
    },
    show: {
        opacity: 1,
        transition: {staggerChildren: 0.02}
    },
};
export const itemElement = {
    hidden: {y: 10, opacity: 0, transition: {type: 'spring', stiffness: 150, mass: 0.2, damping: 15}},
    show: {y: 0, opacity: 1, transition: {type: 'spring', stiffness: 150, mass: 0.2, damping: 15}}
};