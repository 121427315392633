// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.protocol_timestampWrapper__e7uE9 {
    position: relative;
    background-color: white;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px 10px 0px 0px;
    z-index: 9999;
    bottom: -1px;
    border-right: 1px solid #dedfe8;
    border-left: 1px solid #dedfe8;
    border-top: 1px solid #dedfe8;
}
.protocol_timestampWrapper__e7uE9:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 20px;
    right: -10px;
    width: 10px;
    border-right: 1px solid #dedfe8;
    border-top-right-radius: 10px;
    box-shadow: 0 -10px 0 0 #ffffff;
    transform: rotate(180deg);
}
.protocol_protocolWrapper__7K-\\+N {
    border-top-left-radius: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/views/Protocol/protocol.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,uBAAkB;IAAlB,kBAAkB;IAClB,gCAAgC;IAChC,aAAa;IACb,YAAY;IACZ,+BAA+B;IAC/B,8BAA8B;IAC9B,6BAA6B;AACjC;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,+BAA+B;IAC/B,6BAA6B;IAC7B,+BAA+B;IAC/B,yBAAyB;AAC7B;AACA;IACI,sCAAsC;AAC1C","sourcesContent":[".timestampWrapper {\n    position: relative;\n    background-color: white;\n    width: fit-content;\n    border-radius: 10px 10px 0px 0px;\n    z-index: 9999;\n    bottom: -1px;\n    border-right: 1px solid #dedfe8;\n    border-left: 1px solid #dedfe8;\n    border-top: 1px solid #dedfe8;\n}\n.timestampWrapper:after {\n    content: \"\";\n    position: absolute;\n    bottom: 0;\n    height: 20px;\n    right: -10px;\n    width: 10px;\n    border-right: 1px solid #dedfe8;\n    border-top-right-radius: 10px;\n    box-shadow: 0 -10px 0 0 #ffffff;\n    transform: rotate(180deg);\n}\n.protocolWrapper {\n    border-top-left-radius: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timestampWrapper": `protocol_timestampWrapper__e7uE9`,
	"protocolWrapper": `protocol_protocolWrapper__7K-+N`
};
export default ___CSS_LOADER_EXPORT___;
