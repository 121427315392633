import {
    Autocomplete,
    Box,
    Button, Card, CardContent,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select, Slider,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import {useUsers} from "../../apollo/hooks/useUsers";
import {useEffect, useState} from "react";
import {useTasks} from "../../apollo/hooks/useTasks";
import {MobileDatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import {useGlobalStore} from "../../store";
import FileUpload from "../FileUpload";
import {useFiles} from "../../apollo/hooks/useFiles";
import FullpageLoader from "../common/FullPageLoader";
import {useAgendas} from "../../apollo/hooks/useAgendas";
import CreateAgenda from "../Agenda/CreateAgenda";
import Agenda from "../Agenda";
import Files from "../Files";
import Map from "../Map";
import 'moment/locale/de'

const CreateTask = () => {

    const selectedFile = useGlobalStore((state) => state.selectedFile)
    const clearSelectedFile = useGlobalStore((state) => state.clearSelectedFile)

    const [agendaList, setAgendaList] = useState([])
    const [assigned, setAssigned] = useState([])
    const [adress, setAdress] = useState("")
    const [customer, setCustomer] = useState("")
    const [ab, setAb] = useState("")
    const [street, setStreet] = useState("")
    const [number, setNumber] = useState("")
    const [plz, setPlz] = useState("")
    const [city, setCity] = useState("")
    const [startdate, setStartdate] = useState(moment().locale('de'))
    const [enddate, setEnddate] = useState(moment().locale('de'))
    const [tosAccepted, setTosAccepted] = useState(false)
    const [photoPermission, setPhotoPermission] = useState(false)
    const [askedForReview, setAskedForReview] = useState(false)
    const [type, setType] = useState("NEUAUFTRAG")
    const [state, setState] = useState("ENABLED")
    const [tabValue, setTabValue] = useState(0)

    const [radius, setRadius] = useState(0.2)
    const [long, setLong] = useState(null)
    const [lat, setLat] = useState(null)

    const routeParams = useParams()
    const router = useNavigate()
    const {me, users, loading: usersLoading, error: usersError} = useUsers()
    const {
        taskTypes,
        taskStates,
        addTask,
        updateTask,
        getTaskById,
        loading: tasksLoading,
        error: tasksError
    } = useTasks()
    const {uploadFile, loading: filesLoading, error: filesError} = useFiles()
    const {addAgenda, loading: agendasLoading, error: agendasError} = useAgendas()

    useEffect(() => {
        if (routeParams.taskId && !usersLoading) {
            getTaskById(routeParams.taskId).then((val) => {
                //console.log(val.data.getTaskById.assigned.map(id => users?.find(user => user._id === id)))
                setAssigned(val.data.getTaskById.assigned.map(id => users?.find(user => user._id === id)))
                setCustomer(val.data.getTaskById.customer)
                setAb(val.data.getTaskById.ab)
                setStreet(val.data.getTaskById.street)
                setNumber(val.data.getTaskById.number)
                setPlz(val.data.getTaskById.plz)
                setCity(val.data.getTaskById.city)
                setLong(val.data.getTaskById.long)
                setLat(val.data.getTaskById.lat)
                setRadius(val.data.getTaskById.radius)
                setStartdate(moment(val.data.getTaskById.startdate).locale('de'))
                setEnddate(moment(val.data.getTaskById.enddate).locale('de'))
                setTosAccepted(val.data.getTaskById.tos_accepted)
                setPhotoPermission(val.data.getTaskById.photo_permission)
                setAskedForReview(val.data.getTaskById.asked_for_review)
                setType(val.data.getTaskById.type)
                setState(val.data.getTaskById.state)
            })
        }
        // eslint-disable-next-line
    }, [usersLoading])

    if (usersLoading || tasksLoading || filesLoading || agendasLoading) return <FullpageLoader/>
    if (usersError || tasksError || filesError || agendasError) return <FullpageLoader error/>

    const handleSelectAssigned = (event, index) => {
        var assigned = [];
        for (var i = 0; i < index.length; i++) {
            assigned.push(index[i])
        }
        setAssigned(assigned)
    }

    const handleChangeLocation = (event) => {
        setLong(parseFloat(event.long))
        setLat(parseFloat(event.lat))
        if (event.location.features.length > 0) {
            //console.log(event.location.features[0])
            let location = {
                street: event.location.features[0].text,
                number: event.location.features[0].address,
                plz: event.location.features[0].context[0].text,
                ort: event.location.features[0].context[2].text,
                adress: event.location.features[0]?.place_name
            }
            setStreet(location.street)
            setCity(location.ort)
            setPlz(location.plz)
            setNumber(location.number)
            setAdress(location.adress)
        } else {
            setStreet(null)
            setCity(null)
            setPlz(null)
            setNumber(null)
            setAdress("Unbekannte Adresse")
        }

    };

    const handleAddTask = () => {
        const payload = {
            assigned: assigned.map(user => user._id),
            customer: customer,
            ab: ab,
            street: street,
            number: number,
            plz: plz,
            city: city,
            long: long,
            lat: lat,
            radius: parseFloat(radius),
            startdate: startdate,
            enddate: enddate,
            type: type,
            state: state,
            tos_accepted: tosAccepted,
            photo_permission: photoPermission,
            asked_for_review: askedForReview
        }
        addTask(payload).then((res) => {
            setAssigned([])
            setCustomer("")
            setAb("")
            setStreet("")
            setNumber("")
            setPlz("")
            setCity("")
            setLong(null)
            setLat(null)
            setRadius(0.2)
            setStartdate(moment())
            setEnddate(moment())
            setType("NEUAUFTRAG")
            setState("ENABLED")
            setTosAccepted(false)
            setPhotoPermission(false)
            setAskedForReview(false)
            router('/tasks')
            agendaList.map((agenda) => {
                const agendaPayload = {
                    task: res.data.createTask._id.toString(),
                    assigned: agenda.assigned.map(user => user._id),
                    agenda: agenda.agenda,
                    startdate: agenda.startdate,
                    enddate: agenda.enddate,
                    finished: agenda.finished
                }
                addAgenda(agendaPayload)
            })
            selectedFile.map((file) => {
                const inputPayload = {
                    filename: file.name,
                    permission: file.permission,
                    mimetype: file.type,
                    task: res.data.createTask._id.toString(),
                    user: me.me._id
                }
                uploadFile(inputPayload, file).then(() => {
                    clearSelectedFile()
                })
            })
        })
    }

    const handleUpdateTask = () => {
        const payload = {
            assigned: assigned.map(user => user._id),
            customer: customer,
            ab: ab,
            street: street,
            number: number,
            plz: plz,
            city: city,
            long: long,
            lat: lat,
            radius: parseFloat(radius),
            startdate: startdate,
            enddate: enddate,
            type: type,
            state: state,
            tos_accepted: tosAccepted,
            photo_permission: photoPermission,
            asked_for_review: askedForReview
        }
        updateTask(payload, routeParams.taskId).then((res) => {
            setAssigned([])
            setCustomer("")
            setAb("")
            setStreet("")
            setNumber("")
            setPlz("")
            setCity("")
            setLong(null)
            setLat(null)
            setRadius(0.2)
            setStartdate(moment())
            setEnddate(moment())
            setType("NEUAUFTRAG")
            setState("ENABLED")
            setTosAccepted(false)
            setPhotoPermission(false)
            setAskedForReview(false)
            router('/tasks')
            agendaList.map((agenda) => {
                const agendaPayload = {
                    task: routeParams.taskId,
                    assigned: agenda.assigned.map(user => user._id),
                    agenda: agenda.agenda,
                    startdate: agenda.startdate,
                    enddate: agenda.enddate,
                    finished: agenda.finished
                }
                addAgenda(agendaPayload)
            })
            selectedFile.map((file) => {
                const inputPayload = {
                    filename: file.name,
                    permission: file.permission,
                    mimetype: file.type,
                    task: res.data.updateTask._id.toString(),
                    user: me.me._id
                }
                uploadFile(inputPayload, file).then(() => {
                    clearSelectedFile()
                })
            })
        })
    }

    const handleChangeTab = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const renderTaskEditor = () => {
        return (
            <>
                {
                    tabValue === 0 &&
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Auftragsdetails</Typography>
                                    </Box>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel>Auftragstyp</InputLabel>
                                        <Select
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                        >
                                            {taskTypes?.getTaskTypes.map((type, index) => (
                                                <MenuItem key={index} value={type}>{type}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {me.me.role === "ADMIN" &&
                                        <FormControl fullWidth variant="filled">
                                            <InputLabel>Auftragsstatus</InputLabel>
                                            <Select
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                            >
                                                {taskStates?.getTaskStates.map((type, index) => (
                                                    <MenuItem key={index} value={type}>{type}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>}
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={users}
                                        value={assigned && assigned}
                                        onChange={handleSelectAssigned}
                                        getOptionLabel={(option) => option?.prename + " " + option?.surname}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant={'filled'}
                                                label="Zuständige Mitarbeiter"
                                            />
                                        )}
                                    />
                                    <TextField value={customer} onChange={(e) => setCustomer(e.target.value)}
                                               variant={'filled'}
                                               label={'Kunde'}/>
                                    <TextField value={ab} onChange={(e) => setAb(e.target.value)}
                                               variant={'filled'}
                                               label={'AB Nummer'}/>
                                    <Card>
                                        <CardContent>
                                            <Stack direction={'column'} px={3} py={1} spacing={1} alignItems={'center'}>
                                                <Stack direction={'column'} textAlign={'center'}>
                                                    <Typography variant={'h7'}>{radius} km</Typography>
                                                    <Typography variant={'body1'}>Radius</Typography>
                                                </Stack>
                                                <Slider
                                                    value={radius}
                                                    defaultValue={0.2}
                                                    step={0.05}
                                                    min={0.1}
                                                    max={1}
                                                    onChange={(e) => setRadius(e.target.value)}
                                                />
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    <TextField value={adress} fullWidth
                                               variant={'filled'} label={'Adresse'}/>
                                    {routeParams.taskId ? long && lat &&
                                        <Map createMode={false} radius={radius} long={long} lat={lat}
                                             onLocationChange={handleChangeLocation}
                                        />
                                        :
                                        <Map createMode={true} radius={radius} onLocationChange={handleChangeLocation}
                                        />
                                    }
                                    <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                                        <MobileDatePicker slotProps={{
                                            textField: {
                                                label: 'Startdatum',
                                                variant: 'filled',
                                                fullWidth: true
                                            }
                                        }} defaultValue={startdate} displayWeekNumber closeOnSelect value={startdate}
                                                          onChange={(value) => setStartdate(value)}/>
                                        <MobileDatePicker slotProps={{
                                            textField: {
                                                label: 'Enddatum',
                                                variant: 'filled',
                                                fullWidth: true
                                            }
                                        }} minDate={startdate && startdate} defaultValue={enddate} displayWeekNumber
                                                          closeOnSelect value={enddate}
                                                          onChange={(value) => setEnddate(value)}/>
                                    </Stack>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Checkbox checked={tosAccepted}
                                                  onChange={(e) => setTosAccepted(e.target.checked)}/>
                                        <Typography variant={'body1'}>
                                            Der Kunde hat die Widerrufsbelehrung akzeptiert.
                                        </Typography>
                                    </Stack>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Checkbox checked={photoPermission}
                                                  onChange={(e) => setPhotoPermission(e.target.checked)}/>
                                        <Typography variant={'body1'}>
                                            Die Fotoerlaubnis wurde vom Kunden erteilt.
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Paper>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button
                                    disabled={assigned.length === 0 || !type || !customer || !startdate || !enddate || !long || !lat || !radius}
                                    onClick={() => routeParams.taskId ? handleUpdateTask() : handleAddTask()}
                                    variant={'contained'}>{routeParams.taskId ? "Aktualisieren" : "Speichern"}</Button>
                                <Button variant={'contained'}
                                        onClick={() => setTabValue(1)}>Weiter</Button>
                            </Stack>
                        </Box>
                    </Stack>
                }
                {
                    tabValue === 1 &&
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Auftragsdokumente</Typography>
                                    </Box>
                                    {routeParams.taskId && <Files id={routeParams.taskId} editable/>}
                                    <FileUpload/>
                                </Stack>
                            </Box>
                        </Paper>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button
                                    disabled={assigned.length === 0 || !type || !customer || !startdate || !enddate || !long || !lat || !radius}
                                    onClick={() => routeParams.taskId ? handleUpdateTask() : handleAddTask()}
                                    variant={'contained'}>{routeParams.taskId ? "Aktualisieren" : "Speichern"}</Button>
                                <Button variant={'contained'}
                                        onClick={() => setTabValue(0)}>Zurück</Button>
                                <Button disabled={assigned.length === 0} variant={'contained'}
                                        onClick={() => setTabValue(2)}>Weiter</Button>
                            </Stack>
                        </Box>
                    </Stack>
                }
                {
                    tabValue === 2 &&
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <CreateAgenda options={assigned} onChange={(e) => setAgendaList(e)} items={agendaList}/>
                        {routeParams.taskId &&
                            <Paper>
                                <Box p={1}>
                                    <Agenda taskId={routeParams.taskId} showNames showAll/>
                                </Box>
                            </Paper>}
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button variant={'contained'} onClick={() => setTabValue(1)}>Zurück</Button>
                                <Button
                                    disabled={assigned.length === 0 || !type || !customer || !startdate || !enddate || !long || !lat || !radius}
                                    onClick={() => routeParams.taskId ? handleUpdateTask() : handleAddTask()}
                                    variant={'contained'}>{routeParams.taskId ? "Aktualisieren" : "Speichern"}</Button>
                            </Stack>
                        </Box>
                    </Stack>
                }
            </>
        )

    }

    return (
        <Stack direction={'column'} spacing={1}>
            <Paper>
                <Tabs
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    variant="scrollable"
                    color="primary"
                    value={tabValue}
                    onChange={handleChangeTab}
                >
                    <Tab value={0} label={
                        "Auftragsdetails"
                    }/>
                    <Tab value={1} label={
                        "Dokumente & Bilder"
                    }/>
                    <Tab disabled={assigned.length === 0} value={2} label={
                        "Agenda"
                    }/>
                </Tabs>
            </Paper>
            {renderTaskEditor()}
        </Stack>
    )
}

export default CreateTask