import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {ORDER_ENTITY} from "../entities/orderEntity";

export const UPDATE_TASK = gql`
  mutation updateTask($input: TaskInput!, $_id: String!) {
    updateTask(input: $input, _id: $_id) {
        ...TaskEntity
        protocols {
          ...ProtocolEntity
        }
        orders {
          ...OrderEntity
        }
    }
  }
  ${TASK_ENTITY},${PROTOCOL_ENTITY},${ORDER_ENTITY}
`;