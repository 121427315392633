import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Modal,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useState} from "react";
import moment from "moment";
import {useUsers} from "../../apollo/hooks/useUsers";
import FullpageLoader from "../common/FullPageLoader";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {overDue} from "../../dateFunctions";
import {HiArrowLongRight} from "react-icons/hi2";
import {useAgendas} from "../../apollo/hooks/useAgendas";
import {useParams} from "react-router-dom";
import {useTasks} from "../../apollo/hooks/useTasks";

const CreateAgenda = (props) => {

    const routeParams = useParams()
    const [agendaList, setAgendaList] = useState(props.items ? props.items : [])
    const [assigned, setAssigned] = useState([])
    const [agenda, setAgenda] = useState("")
    const [startdate, setStartdate] = useState(moment())
    const [enddate, setEnddate] = useState(moment())
    const [openModal, setOpenModal] = useState(false)

    const {users, loading: usersLoading, error: usersError} = useUsers()
    const {tasks, loading: tasksLoading, error: tasksError} = useTasks()
    const {addAgenda, loading: agendaLoading, error: agendaError} = useAgendas()

    if (usersLoading || agendaLoading || tasksLoading) return <FullpageLoader/>
    if (usersError || agendaError || tasksError) return <FullpageLoader error/>

    const selectedAgenda = tasks.find(task => task._id === routeParams.taskId)

    function handleAddNewAgendaItem() {
        setOpenModal(false)
        const updateAgendaList = [
            ...agendaList,
            {
                assigned: assigned,
                agenda: agenda,
                startdate: startdate,
                enddate: enddate,
                finished: false
            }
        ];
        props.onChange(updateAgendaList)
        setAgendaList(updateAgendaList);
        setAssigned([])
        setAgenda("")
        setStartdate(moment(enddate))
        setEnddate(moment(enddate))
    }

    function handleAddAgendaItemDirect() {
        setOpenModal(false)
        const payload = {
            task: routeParams.taskId,
            assigned: assigned.map(user => user._id),
            agenda: agenda,
            startdate: startdate,
            enddate: enddate,
            finished: false
        }
        addAgenda(payload).then(() => {
            setAssigned([])
            setAgenda("")
            setStartdate(moment(enddate))
            setEnddate(moment(enddate))
            setOpenModal(false)
        })
    }

    const handleRemoveAgendaItem = (index) => {
        var array = [...agendaList]; // make a separate copy of the array
        if (index !== -1) {
            array.splice(index, 1);
            setAgendaList(array)
        }
        props.onChange(array)
    }

    const handleSelectAssigned = (event, index) => {
        var assigned = [];
        for (var i = 0; i < index.length; i++) {
            assigned.push(index[i])
        }
        setAssigned(assigned)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '10px',
    };

    const renderNewAgendaItem = () => {
        return (
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box p={1} sx={{...style, width: 400}}>
                    <Stack direction={'column'} spacing={1}>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={props.options}
                            value={assigned && assigned}
                            onChange={handleSelectAssigned}
                            getOptionLabel={(option) => option.prename + " " + option.surname}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={'filled'}
                                    label="Zuständige Mitarbeiter"
                                />
                            )}
                        />
                        <TextField multiline rows={3} value={agenda} onChange={(e) => setAgenda(e.target.value)}
                                   variant={'filled'}
                                   label={'Agenda'}/>
                        <Stack direction={'row'} spacing={1}>
                            <MobileDatePicker slotProps={{
                                textField: {
                                    label: 'Zu erfüllen ab',
                                    variant: 'filled',
                                    fullWidth: true
                                }
                            }} displayWeekNumber closeOnSelect value={startdate}
                                              onChange={(value) => setStartdate(value)}/>
                            <MobileDatePicker slotProps={{
                                textField: {
                                    label: 'Zu erfüllen bis',
                                    variant: 'filled',
                                    fullWidth: true
                                }
                            }} displayWeekNumber closeOnSelect value={enddate}
                                              onChange={(value) => setEnddate(value)}/>
                        </Stack>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Button disabled={assigned.length === 0 || !agenda || !startdate || !enddate}
                                    onClick={() => selectedAgenda ? handleAddAgendaItemDirect() : handleAddNewAgendaItem()}>Hinzufügen</Button>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        )
    }

    const getUser = (id) => {
        return users.find(user => user._id === id)
    }

    const renderAddButton = () => {
        return (
            <Paper>
                <Box p={2} textAlign={'center'}>
                    <Button variant={'contained'} onClick={() => setOpenModal(true)}>Agendapunkt hinzufügen</Button>
                </Box>
            </Paper>
        )
    }

    const renderAgenda = () => {
        return agendaList.map((item, index) => (
            <Paper key={index}>
                <Box p={1}>
                    <Stack direction={'column'} spacing={1}>
                        <Stack direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
                            <Stack direction={'row'} spacing={1}>
                                {item.assigned.map((user, index) => (
                                    <Chip key={index}
                                          label={getUser(user._id).prename + " " + getUser(user._id).surname}/>
                                ))}
                            </Stack>
                            <Stack direction={'row'} spacing={2}
                                   alignItems={'center'} divider={<HiArrowLongRight/>}>
                                <Stack direction={'column'}>
                                    <Typography
                                        variant={'body2'}>von</Typography>
                                    <Typography
                                        variant={'h7'}>{moment(item.due_to).format('L')}</Typography>
                                </Stack>
                                <Stack direction={'column'}>
                                    <Typography
                                        variant={'body2'}>bis</Typography>
                                    <Typography
                                        color={overDue(item.enddate) ? "secondary" : "#000000"}
                                        variant={'h7'}>{moment(item.enddate).format('L')}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Card>
                            <CardContent>
                                <Stack direction={'column'} p={1}>
                                    <Typography
                                        variant={'body2'}>Agenda</Typography>
                                    <Typography
                                        variant={'body1'}>{item.agenda}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Button onClick={() => handleRemoveAgendaItem(index)} color={'secondary'}>Löschen</Button>
                        </Box>
                    </Stack>
                </Box>
            </Paper>
        ))
    }

    return (
        <Stack direction={'column'} spacing={1}>
            {renderAddButton()}
            {renderNewAgendaItem()}
            {renderAgenda()}
        </Stack>
    )
}

export default CreateAgenda