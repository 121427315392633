import {gql} from '@apollo/client';
import {FEEDBACK_ENTITY} from "../entities/feedbackEntity";

export const UPDATE_FEEDBACK_STATE = gql`
  mutation updateFeedbackState($input: FeedbackStateInput!, $_id: String!) {
    updateFeedbackState(input: $input, _id: $_id) {
        ...FeedbackEntity
    }
  }
  ${FEEDBACK_ENTITY}
`;