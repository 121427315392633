import {Box, Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import styles from "./home.module.css"
import {useUsers} from "../../apollo/hooks/useUsers";
import {useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from 'framer-motion'
import {containerElement, itemElement} from "../../stagger";
import {shadeColor} from "../../shade";
import FullpageLoader from "../common/FullPageLoader";
import {useTasks} from "../../apollo/hooks/useTasks";
import Time from "../Time";
import {useOrders} from "../../apollo/hooks/useOrders";
import {
    CiBellOff,
    CiBellOn,
    CiBoxList,
    CiCalendar,
    CiChat1,
    CiClock2,
    CiEdit,
    CiFolderOn,
    CiLocationOn,
    CiLogout,
    CiMedicalCross,
    CiSettings,
    CiShoppingBasket,
    CiShoppingCart
} from "react-icons/ci";

const Home = () => {

    const router = useNavigate()
    const {me, users, loading: meLoading, error: meError} = useUsers()
    const {tasks, loading: tasksLoading, error: tasksError} = useTasks()
    const {orders, loading: ordersLoading, error: ordersError} = useOrders()

    if (meLoading || tasksLoading || ordersLoading) return <FullpageLoader/>
    if (meError || tasksError || ordersError) return <FullpageLoader error/>

    const handleLogout = () => {
        localStorage.removeItem("jwt")
        window.location.reload()
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    const navButtons = [
        {
            category: 'Zeiterfassung',
            component: <Time/>,
            permissions: ["ADMIN", "MITARBEITER", "KOORDINATOR", "BAULEITER"],
            size: 6
        },
        {
            title: "Erfasste Zeiten",
            icon: <CiClock2/>,
            color: "#40c935",
            action: () => router("/times"),
            permissions: ["ADMIN", "MITARBEITER", "KOORDINATOR", "BAULEITER"],
            category: 'Zeiterfassung',
            size: 6
        },
        {
            title: "Aufgaben",
            icon: <CiBoxList/>,
            color: "#fcbd49",
            action: () => router("/todos"),
            permissions: ["ADMIN", "MITARBEITER", "KOORDINATOR", "BAULEITER"],
            category: 'Aufgaben',
            size: 12,
            hide: true
        },
        {
            title: "Tagesbericht schreiben",
            icon: <CiEdit/>,
            color: "#fcbd49",
            action: () => router("/protocol/create"),
            permissions: ["ADMIN", "BAULEITER", "MITARBEITER"],
            category: 'Aufgaben',
            size: 12
        },
        {
            title: "Bestellung aufgeben",
            icon: <CiShoppingBasket/>,
            color: "#5dbbe3",
            action: () => router("/orders/create"),
            permissions: ["ADMIN", "BAULEITER", "MITARBEITER"],
            category: 'Bestellungen',
            size: 6
        },
        {
            title: "Alle Bestellungen",
            subtitle: orders.filter(order => order.state === "BESTELLT").filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true).length + "/" + orders.filter(order => order.state === "BEARBEITET").filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true).length + "/" + orders.filter(order => order.state === "LIEFERBESTAETIGUNG").filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true).length + "/" + orders.filter(order => order.state === "STORNIERT").filter(order => me.me.role === "MITARBEITER" ? order.user._id === me.me._id : true).length,
            icon: <CiShoppingCart/>,
            color: "#5dbbe3",
            action: () => router("/orders"),
            permissions: ["ADMIN", "KOORDINATOR", "BAULEITER", "MITARBEITER"],
            category: 'Bestellungen',
            size: 6
        },
        {
            title: "Alle Aufträge",
            subtitle: `Neu: ${tasks.filter(task => task.state === "FINISHED" && task.type === "NEUAUFTRAG").length}/${tasks.filter(task => task.type === "NEUAUFTRAG").length} | Wartung: ${tasks.filter(task => task.state === "FINISHED" && task.type === "WARTUNG").length}/${tasks.filter(task => task.type === "WARTUNG").length} | Reklamation: ${tasks.filter(task => task.state === "FINISHED" && task.type === "REPARATUR").length}/${tasks.filter(task => task.type === "REPARATUR").length}`,
            icon: <CiCalendar/>,
            color: "#1495e5",
            action: () => router("/tasks"),
            permissions: ["ADMIN", "KOORDINATOR", "BAULEITER"],
            category: 'Aufträge',
            size: 12
        },
        {
            title: "Aktuelle Aufträge",
            icon: <CiLocationOn/>,
            color: "#1495e5",
            action: () => router("/tasks/current"),
            permissions: ["ADMIN", "KOORDINATOR", "BAULEITER", "MITARBEITER"],
            category: 'Aufträge',
            size: 6
        },
        {
            title: "Neuer Auftrag",
            icon: <CiMedicalCross/>,
            color: "#1495e5",
            action: () => router("/tasks/create"),
            permissions: ["ADMIN", "KOORDINATOR"],
            category: 'Aufträge',
            size: 6
        },
        {
            title: "Feedback & Fehler",
            icon: <CiChat1/>,
            color: "#6957f1",
            action: () => router("/feedback"),
            permissions: ["ADMIN", "KOORDINATOR", "BAULEITER", "MITARBEITER"],
            category: 'Sonstiges',
            size: 6
        },
        {
            title: "Dokumente & Bilder",
            icon: <CiFolderOn/>,
            color: "#6957f1",
            action: () => router("/files"),
            permissions: ["ADMIN", "KOORDINATOR"],
            category: 'Sonstiges',
            size: 6
        },
        {
            title: "Telegram Benachrichtigungen",
            subtitle: me.me.chatId ? "Verbunden - Chat ID " + me.me.chatId : "https://t.me/PoolwunschBot /connect " + me.me._id,
            icon: me.me.notifications ? <CiBellOn/> : <CiBellOff/>,
            color: me.me.notifications ? "#1495e5" : "#ff667f",
            action: () => copyToClipboard("/connect " + me.me._id),
            permissions: ["ADMIN", "KOORDINATOR", "BAULEITER", "MITARBEITER"],
            category: 'Sonstiges',
            size: 12
        },
        {
            title: "Nutzer verwalten",
            subtitle: users.length + " Nutzer",
            icon: <CiSettings/>,
            color: "#a6b0be",
            action: () => router("/users"),
            permissions: ["ADMIN"],
            category: 'Sonstiges',
            size: 6
        },
        {
            title: "Abmelden",
            subtitle: "Angemeldet als " + me.me.prename + " " + me.me.surname,
            icon: <CiLogout/>,
            color: "#ff667f",
            action: () => handleLogout(),
            permissions: ["ADMIN", "KOORDINATOR", "BAULEITER", "MITARBEITER"],
            category: 'Sonstiges',
            size: 6
        },
    ]

    let sortedNavButtons = navButtons.filter(button => button.permissions.includes(me.me.role)).reduce(function (o, cur) {

        var occurs = o.find(ob => ob.category === cur.category);
        if (occurs) {
            occurs.buttons.push(cur)
        } else {
            var obj = {
                category: cur.category,
                buttons: [cur]
            };
            o = o.concat(obj);
        }

        return o;
    }, [])

    const renderCategories = () => {
        return sortedNavButtons.map((button, index) => (
            <Box className={styles.categoryWrapper} p={1}>
                <Stack key={index} direction={'column'} spacing={1}>
                    <Box px={1}>
                        <motion.div
                            key={index}
                            variants={itemElement}
                        >
                            <Typography color={'#60656e'} variant={'h6'}>{button.category}</Typography>
                        </motion.div>
                    </Box>
                    <Box>
                        <Grid container spacing={1}>
                            {renderTasks(button.buttons)}
                        </Grid>
                    </Box>
                </Stack>
            </Box>
        ))
    }

    const renderTasks = (buttons) => {
        return buttons.filter(task => !task.hide).filter(task => task.permissions.includes(me.me.role)).map((task, index) => (
            <Grid key={index} item xs={12} md={task.size}>
                <motion.div
                    key={index}
                    variants={itemElement}
                >
                    <motion.div
                        whileHover={{scale: 1.01}}
                        whileTap={{scale: 0.99}}
                        transition={{type: 'spring', stiffness: 100, mass: 0.1, damping: 10}}>
                        {task.component ?
                            task.component :

                            <Box onClick={() => task.action ? task.action() : ""} className={styles.navButton}
                                 display={'flex'} alignItems={'center'} p={0}>
                                <Stack width={'100%'} direction={'row'} spacing={2} display={'flex'}
                                       alignItems={'center'}>
                                    <Card sx={{borderRadius: '10px 0px 0px 10px'}}>
                                        <CardContent sx={{background: `linear-gradient(90deg, ${task.color}, ${shadeColor(task.color, 5)})`}}>
                                            <Box p={1}>
                                                <Box className={styles.iconWrapper} display={'flex'}
                                                     alignItems={'center'}
                                                     justifyContent={'center'}>
                                                    <Typography style={{lineHeight: '0px', zIndex: 3}}
                                                                color={shadeColor(task.color, 150)}
                                                                variant={'h4'}>{task.icon}</Typography>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    <Stack direction={'column'}>
                                        <Typography variant={'h7'}>{task.title}</Typography>
                                        {task.subtitle && <Typography variant={'body2'}>{task.subtitle}</Typography>}
                                    </Stack>
                                </Stack>
                            </Box>}
                    </motion.div>
                </motion.div>
            </Grid>
        ))
    }

    return (
        <motion.div
            variants={containerElement}
            initial="hidden"
            animate="show"
        >
            <AnimatePresence mode={'wait'}>
                <Stack direction={'column'} spacing={1}>
                    {renderCategories()}
                </Stack>
            </AnimatePresence>
        </motion.div>
    )
}

export default Home