import {Box, Button, Paper, Stack, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {useTasks} from "../../apollo/hooks/useTasks";
import {useUsers} from "../../apollo/hooks/useUsers";
import {useNavigate} from "react-router-dom";
import {useOrders} from "../../apollo/hooks/useOrders";
import OrderHistory from "./OrderHistory";
import moment from "moment/moment";
import {MobileDatePicker} from "@mui/x-date-pickers";
import FullpageLoader from "../common/FullPageLoader";
import {useMessages} from "../../apollo/hooks/useMessages";
import {MdOutlineConstruction} from "react-icons/md";
import {BsArrowRight} from "react-icons/bs";

const CreateOrder = () => {

    const router = useNavigate()

    const [step, setStep] = useState(0)
    const [task, setTask] = useState(null)
    const [order, setOrder] = useState("")
    const [requestDate, setRequestDate] = useState(moment().add(3, 'days'))
    const {tasks, enabledTasks, loading: tasksLoading, error: tasksError} = useTasks()
    const {orders, addOrder, orderStates, loading: ordersLoading, error: ordersError} = useOrders()
    const {me, users, loading: usersLoading, error: usersError} = useUsers()
    const {addMessage, error: messageError} = useMessages()

    if (tasksLoading || usersLoading || ordersLoading) return <FullpageLoader/>
    if (tasksError || usersError || ordersError || messageError) return <FullpageLoader error/>

    const handleSendOrder = () => {
        const payload = {
            user: me.me._id,
            task: task,
            order: order,
            requestdate: requestDate,
            state: orderStates.getOrderStates[0]
        }
        addOrder(payload).then(() => {
            users.filter(user => user.chatId && (user.role === "KOORDINATOR" || user.role === "ADMIN")).map(user => {
                addMessage(me.me.prename + " " + me.me.surname + " hat eine Bestellung aufgegeben.\n\nKunde: " + tasks.find(x => x._id === task).customer + "\nBestellung: " + order + "\n\nZu den Bestellungen: http://localhost:3000/orders", user.chatId)
            })
            router('/')
        })
    }

    const renderStep = () => {
        switch (step) {
            case 0:
                return (
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Für welchen Auftrag möchtest du eine Bestellung
                                            aufgeben?</Typography>
                                    </Box>
                                    {enabledTasks.filter(task => task.assigned.some(assigned => assigned === me.me._id)).map((x, index) => (
                                        <Button onClick={() => {
                                            setTask(x._id)
                                            increaseStep()
                                        }} key={index}>
                                            <Stack direction={'column'}>
                                                <Typography variant={'h4'} color={'inherit'}>
                                                    <MdOutlineConstruction/>
                                                </Typography>
                                                <Typography variant={'h7'} color={'inherit'}>
                                                    {x.customer}
                                                </Typography>
                                                <Stack direction={'row'} spacing={1} divider={<BsArrowRight/>} alignItems={'center'}>
                                                    <Typography variant={'body2'} color={'inherit'}>
                                                        {moment(x?.startdate).format('L')}
                                                    </Typography>
                                                    <Typography variant={'body2'} color={'inherit'}>
                                                        {moment(x?.enddate).format('L')}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Button>
                                    ))}
                                </Stack>
                            </Box>
                        </Paper>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button disabled={!task} variant={'contained'}
                                        onClick={() => increaseStep()}>Weiter</Button>
                            </Stack>
                        </Box>
                    </Stack>
                )
            case 1:
                return (
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <Paper>
                            <Stack direction={'column'} spacing={1} p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Deine Bestellung</Typography>
                                    </Box>
                                    <TextField multiline rows={5} value={order}
                                               onChange={(e) => setOrder(e.target.value)} fullWidth
                                               variant={'filled'} label={'Was wird benötigt?'}/>
                                </Stack>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Zu wann benötigst du deine Bestellung?</Typography>
                                    </Box>
                                    <MobileDatePicker
                                        minDate={moment().add(1, 'days')}
                                        maxDate={moment().isAfter(moment(tasks.find(x => x._id === task).enddate)) && tasks.find(x => x._id === task).state === "ENABLED" ? moment().add(3, 'days') : moment(tasks.find(x => x._id === task).enddate)}
                                        disablePast
                                        slotProps={{
                                            textField: {
                                                label: 'Gewünschtes Lieferdatum',
                                                variant: 'filled',
                                                fullWidth: true
                                            }
                                        }}
                                        displayWeekNumber closeOnSelect value={requestDate}
                                        onChange={(value) => {
                                            setRequestDate(value)
                                        }}/>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Bestellverlauf
                                            für {tasks.find(x => x._id === task).customer}</Typography>
                                    </Box>
                                    <OrderHistory orders={orders} task={task}/>
                                </Stack>
                            </Box>
                        </Paper>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button variant={'contained'} onClick={() => decreaseStep()}>Zurück</Button>
                                <Button disabled={!order} variant={'contained'}
                                        onClick={() => handleSendOrder()}>Bestellung absenden</Button>
                            </Stack>
                        </Box>
                    </Stack>
                )
            default:
                return "Error"
        }
    }

    const increaseStep = () => {
        setStep(step + 1)
    }

    const decreaseStep = () => {
        setStep(step - 1)
    }

    return (
        <>
            {
                tasks.filter(task => task.assigned.some(assigned => assigned === me.me._id)).length === 0 ?
                    <Box my={1}>
                        <Paper>
                            <Box p={2} textAlign={'center'}>
                                <Typography variant={'body2'}>Keine Aufträge vorhanden.</Typography>
                            </Box>
                        </Paper>
                    </Box>
                    :
                    <Stack direction={'column'} spacing={1}>
                        {renderStep()}
                    </Stack>
            }
        </>
    )
}

export default CreateOrder