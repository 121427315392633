// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation_fab__VmuMy {
    font-size: 1.5rem !important;
    box-shadow:
            0px 0px 2.2px rgba(0, 0, 0, 0.02),
            0px 0px 5.3px rgba(0, 0, 0, 0.028),
            0px 0px 10px rgba(0, 0, 0, 0.035),
            0px 0px 17.9px rgba(0, 0, 0, 0.042),
            0px 0px 33.4px rgba(0, 0, 0, 0.05),
            0px 0px 80px rgba(0, 0, 0, 0.07) !important;
}
.navigation_fabContainer__3qFW3 {
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/views/Navigation/navigation.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B;;;;;;uDAMmD;AACvD;AACA;IACI,eAAe;IACf,YAAY;IACZ,SAAS;IACT,YAAY;AAChB","sourcesContent":[".fab {\n    font-size: 1.5rem !important;\n    box-shadow:\n            0px 0px 2.2px rgba(0, 0, 0, 0.02),\n            0px 0px 5.3px rgba(0, 0, 0, 0.028),\n            0px 0px 10px rgba(0, 0, 0, 0.035),\n            0px 0px 17.9px rgba(0, 0, 0, 0.042),\n            0px 0px 33.4px rgba(0, 0, 0, 0.05),\n            0px 0px 80px rgba(0, 0, 0, 0.07) !important;\n}\n.fabContainer {\n    position: fixed;\n    bottom: 20px;\n    left: 50%;\n    z-index: 999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fab": `navigation_fab__VmuMy`,
	"fabContainer": `navigation_fabContainer__3qFW3`
};
export default ___CSS_LOADER_EXPORT___;
