import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_USERS} from "../queries/getAllUsers";
import {ON_USERS_CHANGED} from "../subscriptions/onUsersChanged";
import {CREATE_USER} from "../mutations/createUser";
import {UPDATE_USER} from "../mutations/updateUser";
import {REMOVE_USER} from "../mutations/removeUser";
import {GET_USER_BY_ID} from "../queries/getUserById";
import {ME} from "../queries/me";
import {GET_USER_ROLES} from "../queries/getUserRoles";
import {UPDATE_NOTIFICATION_STATE} from "../mutations/updateNotificationState";

export const useUsers = () => {

    const fetchQuery = GET_ALL_USERS

    const {
        data: dataMe,
        loading: loadingMe,
        error: errorMe,
        refetch: refetchQuery,
    } = useQuery(ME, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {
        data: userRoles,
        loading: loadingRoles,
        error: errorRoles,
    } = useQuery(GET_USER_ROLES, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {data: allUsersSubData} = useSubscription(ON_USERS_CHANGED, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    });

    let userData = allUsersSubData ? allUsersSubData?.onUsersChanged : dataQuery ? dataQuery?.getAllUsers : [];

    //console.log("UserQuery", allUsersSubData?.onUsersChanged, dataQuery?.getAllUsers)

    const [add, {error: errorAdd}] = useMutation(
        CREATE_USER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('user created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_USER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('user updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_USER,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('user removed!')
            },
        },
    );

    const [updateNotificationState, {error: errorNotificationStateUpdate}] = useMutation(
        UPDATE_NOTIFICATION_STATE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('user notification state updated!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_USER_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                //refetchQuery()
                console.log('user found!')
            },
        },
    );

    return {
        users: userData,
        userRoles: userRoles,
        me: dataMe,
        loading: loadingQuery || loadingMe || loadingRoles,
        error: errorQuery || errorAdd || errorRemove || errorUpdate || errorMe || errorRoles || errorNotificationStateUpdate,
        addUser: (input) => add({
            variables: {
                input: input
            }
        }),
        updateUser: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        updateNotificationState: (input, id) => updateNotificationState({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeUser: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getUserById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}