// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.files_file__YHcZT {
    position: relative;
    background-color: #f1f1f1;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    min-height: 150px;
    width: 100%;
}
.files_fileIcon__Dq5Vt {
    font-size: 1.5rem;
}
.files_deleteButton__Zy0kt {
    position: absolute;
}
.files_viewButton__ZO4GE {
    position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/views/Files/files.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".file {\n    position: relative;\n    background-color: #f1f1f1;\n    border-radius: 5px;\n    overflow: hidden;\n    height: 100%;\n    min-height: 150px;\n    width: 100%;\n}\n.fileIcon {\n    font-size: 1.5rem;\n}\n.deleteButton {\n    position: absolute;\n}\n.viewButton {\n    position: absolute;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file": `files_file__YHcZT`,
	"fileIcon": `files_fileIcon__Dq5Vt`,
	"deleteButton": `files_deleteButton__Zy0kt`,
	"viewButton": `files_viewButton__ZO4GE`
};
export default ___CSS_LOADER_EXPORT___;
