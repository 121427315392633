import {gql} from '@apollo/client';
import {ORDER_ENTITY} from "../entities/orderEntity";

export const UPDATE_ORDER = gql`
  mutation updateOrder($input: OrderInput!, $_id: String!) {
    updateOrder(input: $input, _id: $_id) {
        ...OrderEntity
    }
  }
  ${ORDER_ENTITY}
`;