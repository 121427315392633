import React, {useLayoutEffect} from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

//chart type
import * as am5percent from "@amcharts/amcharts5/percent";
import {Box, Card, CardContent, Paper, Stack} from "@mui/material";
import * as am5xy from "@amcharts/amcharts5/xy";
import momentDurationFormatSetup from "moment-duration-format";
import moment from "moment/moment";
import {getDurationInSecondsInt} from "../../dateFunctions";

momentDurationFormatSetup(moment);

function TimeChart(props) {

    const reduceTimes = () => {
        let result
        switch (props.section) {
            case "month":
                result = props.data.times.reduce(function (o, cur) {
                    if (!o) return o
                    //var occurs = o.find(ob => ob.customer._id === cur.task._id && ob.user._id === cur.user._id && moment(ob.day).startOf('day').isSame(moment(cur.starttime).startOf('day')));
                    var occurs = o.find(ob => ob.kw === ("KW " + moment(cur.starttime).isoWeek()));
                    if (occurs) {
                        if (cur.endtime) {
                            occurs.brutto = occurs.brutto + getDurationInSecondsInt(cur.starttime, cur.endtime)
                            occurs.netto = occurs.netto + getDurationInSecondsInt(cur.starttime, cur.endtime)
                            if (cur.type === "DRIVE") {
                                occurs.drive = occurs.drive + getDurationInSecondsInt(cur.starttime, cur.endtime)
                                occurs.netto = occurs.netto - getDurationInSecondsInt(cur.starttime, cur.endtime)
                            }
                            if (cur.type === "BREAK") {
                                occurs.break = occurs.break + getDurationInSecondsInt(cur.starttime, cur.endtime)
                                occurs.netto = occurs.netto - getDurationInSecondsInt(cur.starttime, cur.endtime)
                            }
                            if (cur.type === "LAGER") {
                                occurs.lager = occurs.lager + getDurationInSecondsInt(cur.starttime, cur.endtime)
                                occurs.netto = occurs.netto - getDurationInSecondsInt(cur.starttime, cur.endtime)
                            }
                            if (cur.type === "HAENDLER") {
                                occurs.haendler = occurs.haendler + getDurationInSecondsInt(cur.starttime, cur.endtime)
                                occurs.netto = occurs.netto - getDurationInSecondsInt(cur.starttime, cur.endtime)
                            }
                        }
                    } else {
                        var obj = {
                            kw: ("KW " + moment(cur.starttime).isoWeek()),
                            brutto: cur.endtime ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            netto: cur.endtime && cur.type === "CHECKINCHECKOUT" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            drive: cur.endtime && cur.type === "DRIVE" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            break: cur.endtime && cur.type === "BREAK" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            lager: cur.endtime && cur.type === "LAGER" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            haendler: cur.endtime && cur.type === "HAENDLER" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                        };
                        o = o.concat(obj);
                    }
                    return o;
                }, [])
                return result
            case "week":
                result = props.data.times.reduce(function (o, cur) {
                    if (!o) return o
                    //var occurs = o.find(ob => ob.customer._id === cur.task._id && ob.user._id === cur.user._id && moment(ob.day).startOf('day').isSame(moment(cur.starttime).startOf('day')));
                    var occurs = o.find(ob => ob.kw === moment(cur.starttime).format('dd'));
                    if (occurs) {
                        if (cur.endtime) {
                            occurs.brutto = occurs.brutto + getDurationInSecondsInt(cur.starttime, cur.endtime)
                            occurs.netto = occurs.netto + getDurationInSecondsInt(cur.starttime, cur.endtime)
                            if (cur.type === "DRIVE") {
                                occurs.drive = occurs.drive + getDurationInSecondsInt(cur.starttime, cur.endtime)
                                occurs.netto = occurs.netto - getDurationInSecondsInt(cur.starttime, cur.endtime)
                            }
                            if (cur.type === "BREAK") {
                                occurs.break = occurs.break + getDurationInSecondsInt(cur.starttime, cur.endtime)
                                occurs.netto = occurs.netto - getDurationInSecondsInt(cur.starttime, cur.endtime)
                            }
                            if (cur.type === "LAGER") {
                                occurs.lager = occurs.lager + getDurationInSecondsInt(cur.starttime, cur.endtime)
                                occurs.netto = occurs.netto - getDurationInSecondsInt(cur.starttime, cur.endtime)
                            }
                            if (cur.type === "HAENDLER") {
                                occurs.haendler = occurs.haendler + getDurationInSecondsInt(cur.starttime, cur.endtime)
                                occurs.netto = occurs.netto - getDurationInSecondsInt(cur.starttime, cur.endtime)
                            }
                        }
                    } else {
                        var obj = {
                            kw: moment(cur.starttime).format('dd'),
                            brutto: cur.endtime ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            netto: cur.endtime && cur.type === "CHECKINCHECKOUT" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            drive: cur.endtime && cur.type === "DRIVE" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            break: cur.endtime && cur.type === "BREAK" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            lager: cur.endtime && cur.type === "LAGER" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                            haendler: cur.endtime && cur.type === "HAENDLER" ? getDurationInSecondsInt(cur.starttime, cur.endtime) : 0,
                        };
                        o = o.concat(obj);
                    }
                    return o;
                }, [])
                return result
            default:
                return []
        }
    }


    var data = reduceTimes()

    //const chart = useRef(null);
    const chartID = props.chartID;

    useLayoutEffect(() => {
        //var root = am5.Root.new("chartdiv2");
        var root = am5.Root.new(chartID, {
            useSafeResolution: false
        });

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        var myTheme = am5.Theme.new(root);
        myTheme.rule("Grid").setAll({
            strokeOpacity: 0.2,
            strokeWidth: 0.5,
        });
        myTheme.rule("Label").set("fontSize", "0.9rem");
        root.setThemes([am5themes_Animated.new(root), myTheme]);

        root.durationFormatter.setAll({
            baseUnit: "second",
            durationFormat: "hh'h' mm'm'",
            durationFields: ["valueY"]
        });

        //root.numberFormatter.set("durationFormat", "mm:ss");

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: false,
            wheelY: false,
            layout: root.verticalLayout,
            paddingRight: 0,
            paddingLeft: 0
        }));

        chart.get("colors").set("colors", [
            am5.color(0x6957f1),
            am5.color(0x1495e5),
            am5.color(0x5dbbe3),
            am5.color(0xfcbd49),
            am5.color(0xff667f),
            am5.color(0xd63c55)
        ]);

        var xRenderer = am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minGridDistance: 20
        })

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "kw",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.data.setAll(data);
        //xAxis.get("renderer").grid.template.set("forceHidden", true);

        var yAxis = chart.yAxes.push(am5xy.DurationAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0,
                minGridDistance: 40,
            })
        }));

        let yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            minPosition: 0.1,
            maxPosition: 1
        });

        var legend = chart.children.push(am5.Legend.new(root, {
            nameField: "categoryX",
            centerX: am5.percent(0),
            x: am5.percent(0),
            paddingTop: 15,
            paddingBottom: 15,
            layout: am5.GridLayout.new(root, {
                maxColumns: 3,
                fixedWidthGrid: true
            })
        }));

        legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10
        });

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName, stacked) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "kw",
                stacked: stacked
            }));

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}: {valueY}",
                tooltipY: am5.percent(90),
                fillOpacity: 0.8,
                strokeWidth: 1,
                cornerRadiusTL: 1,
                cornerRadiusTR: 1,
                width: am5.percent(90)
            });

            series.data.setAll(data);
            series.appear();
            legend.data.push(series);
        }

        makeSeries("Gesamt", "brutto", false);
        makeSeries("Auftrag", "netto", false);
        makeSeries("Pause", "break", true);
        makeSeries("Fahrt", "drive", true);
        makeSeries("Lager", "lager", true);
        makeSeries("Händler", "haendler", true);

        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [props.data]);

    return (
        <Stack direction={'column'} spacing={1}>
            <Paper sx={{boxShadow: 'none'}}>
                <Card>
                    <CardContent>
                        <Box p={1}>
                            <Box style={{height: "450px", width: "100%"}} id={chartID}/>
                        </Box>
                    </CardContent>
                </Card>
            </Paper>
        </Stack>
    );
}

export default TimeChart;