import {gql} from '@apollo/client';

export const FEEDBACK_ENTITY = gql`
  fragment FeedbackEntity on FeedbackEntity {
    _id,
    feedback,
    state,
    created_at,
    updated_at
  }
`;