import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {AGENDA_ENTITY} from "../entities/agendaEntity";

export const GET_ALL_AGENDAS = gql`
  query getAllAgendas {
    getAllAgendas {
        ...AgendaEntity
        task {
          ...TaskEntity
        }
    }
  }
  ${AGENDA_ENTITY},${TASK_ENTITY}
`;