import {useEffect, useState} from "react";
import {
    Alert,
    Avatar,
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import {IoIosCloseCircleOutline} from "react-icons/io";
import {AiOutlineFileDone} from "react-icons/ai";
import styles from './fileupload.module.css'
import {useGlobalStore} from "../../store";
import {useFiles} from "../../apollo/hooks/useFiles";
import FullpageLoader from "../common/FullPageLoader";
import {PermissionType} from "./PermissionType";

const FileUpload = (props) => {

    const removeSelectedFile = useGlobalStore((state) => state.removeSelectedFile)
    const setSelectedFile = useGlobalStore((state) => state.setSelectedFile)
    const selectedFile = useGlobalStore((state) => state.selectedFile)
    const setSelectedFileArray = useGlobalStore((state) => state.setSelectedFileArray)

    const [fileError, setFileError] = useState('')
    const [loading] = useState(false)
    const [maxFilesize] = useState(52428800)

    const {filePermissions, loading: filesLoading, error: filesError} = useFiles()

    useEffect(() => {
        setSelectedFileArray(props.files)
    }, [props.files]);

    if (filesLoading) return <FullpageLoader/>
    if (filesError) return <FullpageLoader error/>

    const handleFileChange = (event) => {
        setFileError('')
        const maxFiles = props.maxFiles ? props.maxFiles : 20
        if (
            event.target.files.length > maxFiles ||
            event.target.files.length + selectedFile.length > maxFiles
        ) {
            setFileError('Es können maximal ' + maxFiles + ' Dateien angehängt werden.')
            return false
        } else {
            for (let i = 0; i < event.target.files.length; i++) {
                if (event.target.files[i].size >= maxFilesize) {
                    setFileError(
                        'Eine oder mehrere der ausgewählten Dateien ist zu groß. Die betroffenen Dateien wurden entfernt.'
                    )
                } else {
                    event.target.files[i].permission = "ALL"
                    setSelectedFile(event.target.files[i])
                }
            }
        }
    }

    const handleChangeFilePermission = (permission, index) => {
        let filesArray = [...selectedFile]
        filesArray[index].permission = permission
        setSelectedFileArray(filesArray)
    }

    const handleDeleteFile = (deleteIndex) => {
        removeSelectedFile(deleteIndex)
    }

    const dragOver = (e) => {
        setFileError('')
        e.preventDefault()
    }

    const dragEnter = (e) => {
        setFileError('')
        e.preventDefault()
    }

    const dragLeave = (e) => {
        setFileError('')
        e.preventDefault()
    }

    const fileDrop = (e) => {
        setFileError('')
        e.preventDefault()
        const files = e.dataTransfer.files
        const maxFiles = props.maxFiles ? props.maxFiles : 20
        if (files.length > maxFiles || files.length + selectedFile.length > maxFiles) {
            setFileError('Es können maximal ' + maxFiles + ' Dateien angehängt werden.')
            return false
        } else {
            for (let i = 0; i < files.length; i++) {
                if (files[i].size >= maxFilesize) {
                    setFileError(
                        'Eine oder mehrere der ausgewählten Dateien ist zu groß. Die betroffenen Dateien wurden entfernt.'
                    )
                } else {
                    files[i].permission = "ALL"
                    setSelectedFile(files[i])
                }
            }
        }
    }

    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return '0 Byte'
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    }

    const renderSelectedFiles = () => {
        if (selectedFile) {
            return Array.from(selectedFile).map((file, index) => (
                <Box key={index} p={1} className={
                    file.size >= maxFilesize ? styles.fileWrapper + styles.toolarge : styles.fileWrapper
                }>
                    <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Avatar>
                                    <AiOutlineFileDone/>
                                </Avatar>
                                <Box>
                                    <Typography variant={'body1'}>{file.name}</Typography>
                                </Box>
                            </Stack>
                            <Stack direction={'row'} spacing={1} alignItems={'center'} width={'150px'}
                                   justifyContent={'end'}>
                                <Typography variant={'body2'}>
                                    {bytesToSize(file.size)}
                                </Typography>
                                <IconButton
                                    color={'secondary'}
                                    edge='end'
                                    aria-label='delete'
                                    disabled={loading}
                                    onClick={() => handleDeleteFile(index)}
                                >
                                    <IoIosCloseCircleOutline/>
                                </IconButton>
                            </Stack>
                        </Stack>
                        {!props.disablePermissions &&
                            <FormControl fullWidth variant="filled" size={'small'}>
                                <InputLabel>Berechtigung</InputLabel>
                                <Select
                                    sx={{ backgroundColor: '#dcdcdc' }}
                                    value={selectedFile[index].permission}
                                    onChange={(e) => handleChangeFilePermission(e.target.value, index)}
                                >
                                    {filePermissions?.getFilePermissions.map((type, index) => (
                                        <MenuItem key={index} value={type}><PermissionType type={type}/></MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                    </Stack>
                </Box>
            ))
        }
    }

    return (
        <Stack direction={'column'} spacing={1}>
            {fileError && (
                <Box>
                    <Alert severity='error'>{fileError}</Alert>
                </Box>
            )}
            {selectedFile && (renderSelectedFiles())}
            {selectedFile.length <= 20 && (
                <Box
                    className={styles.fileUploadWrapper}
                    display={'grid'}
                    alignItems={'center'}
                    justifyItems={'center'}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                >
                    <Box textAlign={'center'}>
                        <input
                            style={{display: 'none'}}
                            id='raised-button-file'
                            multiple
                            type='file'
                            onChange={handleFileChange}
                        />
                        <label
                            htmlFor='raised-button-file'
                        >
                            <Stack direction={'column'} spacing={0}>
                                <Box>
                                    <Button
                                        disabled={loading}
                                        disableElevation
                                        component='span'
                                    >
                                        {selectedFile.length
                                            ? 'weitere Dateien auswählen'
                                            : 'Dateien auswählen'}
                                    </Button>
                                </Box>
                                <Typography
                                    variant={'body2'}>Maximal {props.maxFiles ? props.maxFiles : 20} Dateien</Typography>
                            </Stack>
                        </label>
                    </Box>
                </Box>
            )}
        </Stack>
    )
}

export default FileUpload