import {gql} from '@apollo/client';
import {ORDER_ENTITY} from "../entities/orderEntity";
import {TASK_ENTITY} from "../entities/taskEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const GET_ORDER_BY_ID = gql`
  query getOrderById($_id: String!){
    getOrderById(_id: $_id) {
        ...OrderEntity
        task {
          ...TaskEntity
        }
        user {
          ...UserEntity
        }
        responsible {
          ...UserEntity
        }
    }
  }
  ${ORDER_ENTITY},${TASK_ENTITY},${USER_ENTITY}
`;