import React  from 'react'
import { motion } from 'framer-motion'

const variants = {
    hidden: { opacity: 0, x: 0, y: 10 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: -10 },
}

const PageTransition = ({children}) => (
        <motion.main
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={variants}
            transition={{ type: 'spring', stiffness: 190, mass: 0.1, damping: 10 }}
        >
            {children}
        </motion.main>
)

export default PageTransition