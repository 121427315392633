import {gql} from '@apollo/client';
import {FILE_ENTITY} from "../entities/fileEntity";

export const UPDATE_FILE_PERMISSIONS = gql`
  mutation updateFilePermissions($input: FilePermissionInput!, $_id: String!) {
    updateFilePermissions(input: $input, _id: $_id) {
        ...FileEntity
    }
  }
  ${FILE_ENTITY}
`;