import {Stack, Typography} from "@mui/material";
import {BsCarFrontFill} from "react-icons/bs";
import {BiSolidCoffee} from "react-icons/bi";
import {PiGarageFill} from "react-icons/pi";
import {FaStore} from "react-icons/fa";

const TimeType = (props) => {
    switch (props.type) {
        case "DRIVE":
            return (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <BsCarFrontFill/>
                    <Typography variant={'body1'}>Fahrt</Typography>
                </Stack>
            )
        case "BREAK":
            return (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <BiSolidCoffee/>
                    <Typography variant={'body1'}>Pause</Typography>
                </Stack>
            )
        case "LAGER":
            return (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <PiGarageFill/>
                    <Typography variant={'body1'}>Lager</Typography>
                </Stack>
            )
        case "HAENDLER":
            return (
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <FaStore/>
                    <Typography variant={'body1'}>Händler</Typography>
                </Stack>
            )
        default:
            return "Fehler"
    }
}

export default TimeType