import { gql } from '@apollo/client';

import {USER_ENTITY} from "../entities/userEntity";
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {ORDER_ENTITY} from "../entities/orderEntity";
import {TIME_ENTITY} from "../entities/timeEntity";
import {FILE_ENTITY} from "../entities/fileEntity";

export const ON_USERS_CHANGED = gql`
  subscription {
    onUsersChanged {
        ...UserEntity
        protocols {
          ...ProtocolEntity
        }
        files {
          ...FileEntity
        }
        orders {
          ...OrderEntity
        }
        times {
          ...TimeEntity
        }
    }
  }
  ${USER_ENTITY},${PROTOCOL_ENTITY},${ORDER_ENTITY},${TIME_ENTITY},${FILE_ENTITY}
`;