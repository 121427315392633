import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_FILES} from "../queries/getAllFiles";
import {ON_FILES_CHANGED} from "../subscriptions/onFilesChanged";
import {UPLOAD_FILE} from "../mutations/uploadFile";
import {REMOVE_FILE} from "../mutations/removeFile";
import {GET_FILE_PERMISSIONS} from "../queries/getFilePermissions";
import {UPDATE_ORDER_STATE} from "../mutations/updateOrderState";
import {UPDATE_FILE_PERMISSIONS} from "../mutations/updateFilePermissions";

export const useFiles = () => {

    const fetchQuery = GET_ALL_FILES

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {
        data: filePermissions,
        loading: loadingFilePermissions,
        error: errorFilePermissions,
    } = useQuery(GET_FILE_PERMISSIONS, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {data: allFilesSubData} = useSubscription(ON_FILES_CHANGED, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    });

    let fileData = allFilesSubData ? allFilesSubData?.onFilesChanged : dataQuery ? dataQuery?.getAllFiles : [];

    //console.log("FileQuery", allFilesSubData?.onFilesChanged, dataQuery?.getAllFiles)

    const [add, {error: errorAdd}] = useMutation(
        UPLOAD_FILE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('file uploaded!')
            },
        },
    );

    const [updatePermissions, {error: errorPermissionsUpdate}] = useMutation(
        UPDATE_FILE_PERMISSIONS,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('file permissions updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_FILE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('file removed!')
            },
        },
    );

    return {
        files: fileData,
        filePermissions: filePermissions,
        loading: loadingQuery || loadingFilePermissions,
        error: errorQuery || errorAdd || errorRemove || errorFilePermissions || errorPermissionsUpdate,
        uploadFile: (input, file) => add({
            variables: {
                input: input,
                file: file
            }
        }),
        updateFilePermissions: (input, id) => updatePermissions({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeFile: (id) => remove({
            variables: {
                _id: id
            }
        })
    }
}