import {Chip} from "@mui/material";

const TaskType = (props) => {
    switch(props.state){
        case "ENABLED":
            return(
                <Chip color={'primary'} label={'Aktiv'}/>
            )
        case "DISABLED":
            return(
                <Chip color={'secondary'} label={'Inaktiv'}/>
            )
        case "FINISHED":
            return(
                <Chip color={'primary'} label={'Abgeschlossen'}/>
            )
        case "UEBERGABE":
            return(
                <Chip color={'primary'} label={'Übergabe'}/>
            )
        default:
            return "Fehler"
    }
}

export default TaskType