import {gql} from '@apollo/client';
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";

export const CREATE_PROTOCOL = gql`
  mutation createProtocol($input: ProtocolInput!) {
    createProtocol(input: $input) {
        ...ProtocolEntity
    }
  }
  ${PROTOCOL_ENTITY}
`;