import {useLazyQuery, useMutation, useQuery, useSubscription} from '@apollo/client'
import {ON_TASKS_CHANGED} from "../subscriptions/onTasksChanged";
import {CREATE_TASK} from "../mutations/createTask";
import {UPDATE_TASK} from "../mutations/updateTask";
import {REMOVE_TASK} from "../mutations/removeTask";
import {GET_TASK_BY_ID} from "../queries/getTaskById";
import {GET_ALL_TASKS} from "../queries/getAllTasks";
import {GET_TASK_TYPES} from "../queries/getTaskTypes";
import {useUsers} from "./useUsers";
import {GET_TASK_STATES} from "../queries/getTaskStates";
import {UPDATE_TASK_STATE} from "../mutations/updateTaskState";
import moment from "moment";

export const useTasks = () => {

    const {me} = useUsers()
    const fetchQuery = GET_ALL_TASKS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {
        data: taskTypes,
        loading: loadingTaskTypes,
        error: errorTaskTypes,
    } = useQuery(GET_TASK_TYPES, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {
        data: taskStates,
        loading: loadingTaskStates,
        error: errorTaskStates,
    } = useQuery(GET_TASK_STATES, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {data: allTasksSubData} = useSubscription(ON_TASKS_CHANGED, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    });

    let taskData = allTasksSubData ? allTasksSubData?.onTasksChanged : dataQuery ? dataQuery?.getAllTasks : [];

    //console.log("TaskQuery", allTasksSubData?.onTasksChanged, dataQuery?.getAllTasks)

    const [add, {error: errorAdd}] = useMutation(
        CREATE_TASK,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('task created!')
            },
        },
    );

    const [update, {error: errorUpdate}] = useMutation(
        UPDATE_TASK,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('task updated!')
            },
        },
    );

    const [updateState, {error: errorUpdateState}] = useMutation(
        UPDATE_TASK_STATE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('task state updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_TASK,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('task removed!')
            },
        },
    );

    const [getById] = useLazyQuery(
        GET_TASK_BY_ID,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                //refetchQuery()
                console.log('task found!')
            },
        },
    );

    return {
        tasks: taskData,
        myFutureTasks: taskData.filter(task => task.assigned.includes(me?.me._id)).filter(task => task.state === "ENABLED"),
        enabledTasks: taskData.filter(task => task.state === "ENABLED").filter(task => moment().startOf('day').isSameOrAfter(moment(task.startdate).startOf('day'))),
        myTasks: taskData?.filter(task => task.state === "ENABLED").filter(task => task.assigned.includes(me?.me._id)).filter(task => moment().startOf('day').isSameOrAfter(moment(task.startdate).startOf('day'))),
        taskTypes: taskTypes,
        taskStates: taskStates,
        loading: loadingQuery || loadingTaskTypes || loadingTaskStates,
        error: errorQuery || errorAdd || errorRemove || errorUpdate || errorTaskTypes || errorTaskStates || errorUpdateState,
        addTask: (input) => add({
            variables: {
                input: input
            }
        }),
        updateTask: (input, id) => update({
            variables: {
                input: input,
                _id: id,
            }
        }),
        updateTaskState: (input, id) => updateState({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeTask: (id) => remove({
            variables: {
                _id: id
            }
        }),
        getTaskById: (id) => getById({
            variables: {
                _id: id,
            }
        })
    }
}