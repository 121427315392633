import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {ORDER_ENTITY} from "../entities/orderEntity";
import {FILE_ENTITY} from "../entities/fileEntity";
import {TIME_ENTITY} from "../entities/timeEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const GET_ALL_TIMES = gql`
  query getAllTimes {
    getAllTimes {
        ...TimeEntity
        task {
          ...TaskEntity
        }
        user {
          ...UserEntity
        }
    }
  }
  ${TIME_ENTITY},${TASK_ENTITY},${USER_ENTITY}
`;