import {useFeedbacks} from "../../apollo/hooks/useFeedbacks";
import FullpageLoader from "../common/FullPageLoader";
import {Box, Button, Card, CardContent, Chip, Paper, Stack, TextField, Typography} from "@mui/material";
import {useUsers} from "../../apollo/hooks/useUsers";
import {useState} from "react";
import {BsFillPersonFill} from "react-icons/bs";
import moment from "moment";
import FeedbackState from "./FeedbackState";
import {useMessages} from "../../apollo/hooks/useMessages";
import User from "../common/User";

const Feedback = () => {

    const {
        feedbacks,
        addFeedback,
        removeFeedback,
        updateFeedbackState,
        myFeedbacks,
        loading: feedbackLoading,
        error: feedbackError
    } = useFeedbacks()
    const {me, users, loading: usersLoading, error: usersError} = useUsers()
    const {addMessage, error: messageError} = useMessages()

    const [feedback, setFeedback] = useState("")

    if (feedbackLoading || usersLoading) return <FullpageLoader/>
    if (feedbackError || usersError || messageError) return <FullpageLoader error/>

    const handleAddFeedback = () => {
        const payload = {
            user: me.me._id,
            feedback: feedback
        }
        addFeedback(payload).then(() => {
            users.filter(user => user.chatId && (user.role === "ADMIN")).map(user => {
                addMessage(me.me.prename + " " + me.me.surname + " hat Feedback eingereicht.\n\nFeedback: " + feedback + "\n\nZum Feedback: http://localhost:3000/feedback", user.chatId)
            })
            setFeedback("")
        })
    }

    const renderAddFeedback = () => {
        return (
            <Paper>
                <Box p={1}>
                    <Stack direction={'column'} spacing={1}>
                        <TextField value={feedback} onChange={(e) => setFeedback(e.target.value)} variant={'filled'}
                                   label={'Feedback'} fullWidth multiline rows={5}/>
                        <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}
                               alignItems={'center'}>
                            <Box px={1}>
                                <Typography variant={'body2'}>Feedback senden
                                    als {me.me.prename + " " + me.me.surname}</Typography>
                            </Box>
                            <Button onClick={() => handleAddFeedback()}>Absenden</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
        )
    }

    const renderFeebacks = () => {
        let feedbackArray = []
        if (me.me.role !== "ADMIN") {
            feedbackArray = myFeedbacks
        } else {
            feedbackArray = feedbacks
        }
        return (
            <Stack direction={'column'} spacing={1}>
                {feedbackArray.map((feedback, index) => (
                    <Paper key={index}>
                        <Box p={1}>
                            <Stack direction={'column'} spacing={1}>
                                <Stack direction={'row'} spacing={1}>
                                    <FeedbackState state={feedback.state}/>
                                    <User userId={feedback.user._id}/>
                                    <Chip label={moment(feedback.created_at).format('LLLL')}/>
                                </Stack>
                                <Card>
                                    <CardContent>
                                        <Box p={1}>
                                            <Typography variant={'body1'}>{feedback.feedback}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                                {me.me.role === "ADMIN" &&
                                    <Stack direction={'row'} spacing={1} display={'flex'}
                                           justifyContent={'space-between'}>
                                        <Stack direction={'row'} spacing={1}>
                                            <Button disabled={feedback.state === "RECEIVED"}
                                                    onClick={() => updateFeedbackState({state: "RECEIVED"}, feedback._id)}>Erhalten</Button>
                                            <Button disabled={feedback.state === "IN_PROGRESS"}
                                                    onClick={() => updateFeedbackState({state: "IN_PROGRESS"}, feedback._id)}>In
                                                Bearbeitung</Button>
                                            <Button disabled={feedback.state === "DONE"}
                                                    onClick={() => updateFeedbackState({state: "DONE"}, feedback._id)}>Erledigt</Button>
                                        </Stack>
                                        <Button onClick={() => removeFeedback(feedback._id)}
                                                color={'secondary'}>Löschen</Button>
                                    </Stack>}
                            </Stack>
                        </Box>
                    </Paper>
                ))}
            </Stack>
        )
    }

    return (
        <Stack direction={'column'} spacing={1}>
            {renderAddFeedback()}
            {renderFeebacks()}
        </Stack>
    )
}

export default Feedback