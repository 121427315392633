import {gql} from '@apollo/client';
import {USER_ENTITY} from "../entities/userEntity";
import {FEEDBACK_ENTITY} from "../entities/feedbackEntity";

export const GET_ALL_FEEDBACKS = gql`
  query getAllFeedbacks {
    getAllFeedbacks {
        ...FeedbackEntity
        user {
          ...UserEntity
        }
    }
  }
  ${FEEDBACK_ENTITY},${USER_ENTITY}
`;