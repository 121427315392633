import {gql} from '@apollo/client';
import {TASK_ENTITY} from "../entities/taskEntity";
import {FILE_ENTITY} from "../entities/fileEntity";
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {ORDER_ENTITY} from "../entities/orderEntity";
import {USER_ENTITY} from "../entities/userEntity";

export const ON_FILES_CHANGED = gql`
  subscription {
    onFilesChanged {
        ...FileEntity
        task {
          ...TaskEntity
        }
        user {
          ...UserEntity
        }
        protocol {
          ...ProtocolEntity
        }
        order {
          ...OrderEntity
        }
    }
  }
  ${FILE_ENTITY},${TASK_ENTITY},${PROTOCOL_ENTITY},${ORDER_ENTITY},${USER_ENTITY}
`;