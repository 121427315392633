import {useNavigate, useParams} from "react-router-dom";
import {useTasks} from "../../apollo/hooks/useTasks";
import {useOrders} from "../../apollo/hooks/useOrders";
import {useUsers} from "../../apollo/hooks/useUsers";
import Protocol from "../Protocol";
import {Alert, Box, Button, Card, CardContent, Chip, Grid, Paper, Stack, Tab, Tabs, Typography} from "@mui/material";
import {HiArrowLongRight} from "react-icons/hi2";
import moment from "moment/moment";
import TaskType from "./TaskType";
import OrderHistory from "../Orders/OrderHistory";
import Files from "../Files";
import FullpageLoader from "../common/FullPageLoader";
import {getBusinessDays, getBusinessDaysDelay, overDue, overDueExact} from "../../dateFunctions";
import Agenda from "../Agenda";
import {useState} from "react";
import {BsFillPersonFill} from "react-icons/bs";
import {BiTimeFive} from "react-icons/bi";
import TaskState from "./TaskState";
import User from "../common/User";

const ViewTask = () => {

    const routeParams = useParams()
    const router = useNavigate()
    const {tasks, removeTask, updateTaskState, loading: tasksLoading, error: tasksError} = useTasks()
    const {orders, loading: ordersLoading, error: ordersError} = useOrders()
    const {me, loading: usersLoading, error: usersError} = useUsers()
    const [tabValue, setTabValue] = useState(0)

    if (usersLoading || tasksLoading || ordersLoading) return <FullpageLoader position={'relative'}/>
    if (usersError || tasksError || ordersError) return <FullpageLoader error position={'relative'}/>

    const handleChangeTab = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const handleUpdateTaskState = (id) => {
        const payload = {
            state: "FINISHED",
        }
        updateTaskState(payload, id)
    }

    const renderTasks = () => {
        return tasks.filter(task => task._id === routeParams.id).map((task, index) => (
            <Stack key={index} spacing={0} direction={'column'}>
                <Paper>
                    <Stack direction={'column'} spacing={1} p={1}>
                        {!task.tos_accepted && <Alert severity={'error'}>Der Auftrag ist gesperrt, da die Widerrufsbelehrung noch nicht akzeptiert wurde.</Alert>}
                        <Box pb={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Stack direction={'column'} p={1}>
                                        <Typography variant={'h6'}>{task.customer}</Typography>
                                        <Typography variant={'body2'}>{task.street + " " + task.number}</Typography>
                                        <Typography variant={'body2'}>{task.plz + " " + task.city}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} display={'flex'}
                                      sx={{justifyContent: {xs: 'start', md: 'end'}}}>
                                    <Stack p={1} direction={'row'} spacing={1} alignItems={'center'}
                                           divider={<HiArrowLongRight/>}>
                                        <Stack direction={'column'}>
                                            <Typography
                                                variant={'body2'}>Start</Typography>
                                            <Typography
                                                variant={'h7'}>{moment(task.startdate).format('L')}</Typography>
                                        </Stack>
                                        <Stack direction={'column'}>
                                            <Typography
                                                variant={'body2'}>Ende</Typography>
                                            <Typography
                                                color={overDue(task.enddate) ? "secondary" : "#000000"}
                                                variant={'h7'}>{moment(task.enddate).format('L')}</Typography>
                                        </Stack>
                                        {overDueExact(task.enddate) &&
                                            <Stack direction={'column'}>
                                                <Typography
                                                    variant={'body2'}>Verzugstage</Typography>
                                                <Typography
                                                    variant={'h7'}>{getBusinessDaysDelay(task.enddate, moment())}</Typography>
                                            </Stack>}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Grid item xs={12}>
                                <Stack direction={'column'} spacing={1}>
                                    <Stack direction={'row'} spacing={1}>
                                        <TaskState state={task.state}/>
                                        <TaskType type={task.type}/>
                                        <Chip icon={<BiTimeFive/>}
                                              label={"Dauer: " + getBusinessDays(task.startdate, task.enddate)}></Chip>
                                    </Stack>
                                    <Stack direction={'row'} spacing={1}>
                                        {task.assigned.map((user, index) => (
                                            <User key={index} userId={user}/>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Box>
                        <Card>
                            <CardContent>
                                <Tabs
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                    variant="scrollable"
                                    color="primary"
                                    value={tabValue}
                                    onChange={handleChangeTab}
                                >
                                    <Tab value={0} label={
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography variant={'h7'}>
                                                Agenda
                                            </Typography>
                                        </Stack>
                                    }/>
                                    <Tab value={1} label={
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography variant={'h7'}>
                                                Bestellungen
                                            </Typography>
                                        </Stack>
                                    }/>
                                    <Tab value={2} label={
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography variant={'h7'}>
                                                Dokumente & Bilder
                                            </Typography>
                                        </Stack>
                                    }/>
                                </Tabs>
                            </CardContent>
                        </Card>
                        {tabValue === 0 &&
                            <Agenda showNames taskId={task?._id} showAll={true}/>
                        }
                        {tabValue === 1 &&
                            <OrderHistory orders={orders} task={task._id}/>
                        }
                        {tabValue === 2 &&
                            <Files id={task._id}/>
                        }

                        {(me.me.role === "ADMIN" || me.me.role === "KOORDINATOR") &&
                            <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                                <Stack direction={'row'} spacing={1}>
                                    <Button color={'primary'}
                                            onClick={() => router('/tasks/edit/' + task?._id)}>Bearbeiten</Button>
                                    <Button disabled={task.state === "FINISHED" || !task.tos_accepted} color={'primary'}
                                            onClick={() => handleUpdateTaskState(task?._id)}>Abschließen</Button>
                                </Stack>
                                {me.me.role === "ADMIN" &&
                                    <Button color={'secondary'}
                                            onClick={() => {
                                                removeTask(task?._id)
                                                router('/tasks')
                                            }}>Löschen</Button>}
                            </Stack>}
                    </Stack>
                </Paper>
                <Protocol taskId={task?._id}/>
            </Stack>
        ))
    }

    return (
        <Stack direction={'column'} spacing={1}>
            {renderTasks()}
        </Stack>
    )
}

export default ViewTask