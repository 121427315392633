import {gql} from '@apollo/client';

export const AGENDA_ENTITY = gql`
  fragment AgendaEntity on AgendaEntity {
    _id,
    agenda,
    assigned,
    startdate,
    enddate,
    finished,
    created_at,
    updated_at
  }
`;