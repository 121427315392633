import {Chip} from "@mui/material";

const FeedbackState = (props) => {
    switch (props.state) {
        case "RECEIVED":
            return (
                <Chip color={'primary'} label={'Erhalten'}/>
            )
        case "IN_PROGRESS":
            return (
                <Chip sx={{backgroundColor: '#e18e1a'}} color={'primary'} label={'In Bearbeitung'}/>
            )
        case "DONE":
            return (
                <Chip sx={{backgroundColor: '#419a1d'}} color={'primary'} label={'Erledigt'}/>
            )
        default:
            return "Fehler"
    }
}

export default FeedbackState