import {useEffect, useRef, useState} from "react";
import mapboxgl from 'mapbox-gl'
import {Box} from "@mui/material";
import styles from './map.module.css'
import pin from '../../resources/img/pin.svg'
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const Map = (props) => {

    const mapContainerRef = useRef(null);
    const [mapObject, setMapObject] = useState(null)
    const [geocoderObject, setGeocoderObject] = useState(null)
    const radius = useRef(null);
    const map = useRef(null);
    const marker = useRef(null);
    const geocoder = useRef(null);

    let fs = document.createElement('div');
    fs.className = 'marker';
    fs.id = 'marker'
    //el.style.backgroundImage = 'url('+backgroundImage+')';
    fs.style.width = '35px';
    fs.style.height = '35px';
    fs.style.color = '#000000';
    fs.style.opacity = '1';
    fs.style.backgroundSize = 'cover';
    fs.style.backgroundImage = 'url(' + pin + ')';

    mapboxgl.accessToken = window.env.MAPBOX_TOKEN;

    var createGeoJSONCircle = function (center, radiusInKm, points) {
        if (!points) points = 64;

        var coords = {
            latitude: center[1],
            longitude: center[0]
        };

        var km = radiusInKm;

        var ret = [];
        var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
        var distanceY = km / 110.574;

        var theta, x, y;
        for (var i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = distanceX * Math.cos(theta);
            y = distanceY * Math.sin(theta);

            ret.push([coords.longitude + x, coords.latitude + y]);
        }
        ret.push(ret[0]);

        return {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [ret]
                    }
                }]
            }
        };
    };

    const getLocationObject = async (long, lat) => {
        const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?types=address&access_token=${mapboxgl.accessToken}`);
        const myJson = await response.json(); //extract JSON from the http response
        return myJson
    }

    const setLayer = async (long, lat, radius) => {

        if(marker.current) {
            marker.current.remove()
        }

        marker.current = new mapboxgl.Marker(fs)
            .setLngLat([
                long,
                lat
            ]).addTo(mapObject)

        map.current.flyTo({
            center: [long, lat]
        })

        if (mapObject.getSource('polygon')) {
            mapObject.removeLayer('polygon').removeSource('polygon')
            mapObject.addSource("polygon", createGeoJSONCircle([long, lat], radius));
        } else {
            mapObject.addSource("polygon", createGeoJSONCircle([long, lat], radius));
        }
        mapObject.addLayer({
            "id": "polygon",
            "type": "fill",
            "source": "polygon",
            "layout": {},
            "paint": {
                "fill-color": "#35b5da",
                "fill-opacity": 0.3
            }
        });
        props.onLocationChange({
            long: long,
            lat: lat,
            location: await getLocationObject(long, lat)
        })
    }

    useEffect(() => {
        if (map.current) return; // initialize map only once
        navigator.geolocation.getCurrentPosition(function (position) {
            map.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/blechkelle/ckgm2bq3701zp19n03c86qwof',
                center: [
                    props?.long ? props.long : position.coords.longitude,
                    props?.lat ? props.lat : position.coords.latitude
                ],
                zoom: 14,
                interactive: true,
                attributionControl: false,
            })
            geocoder.current = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false
            })
            map.current.addControl(
                geocoder.current
            );
            setMapObject(map.current)
            setGeocoderObject(geocoder.current)
        })
        radius.current = props.radius
    }, [props.createMode]);

    useEffect(() => {
        if(map.current && props.long && props.lat) {
            if(map.current.isStyleLoaded()) {
                setLayer(props.long, props.lat, radius.current ? radius.current : 0.2)
            } else {
                map.current.on('load', function (e) {
                    setLayer(props.long, props.lat, radius.current ? radius.current : 0.2)
                });
            }
        }
    }, [mapObject]);

    useEffect(() => {
        radius.current = props.radius
        if(marker.current) {
            setLayer(marker.current?._lngLat.lng, marker.current?._lngLat.lat, radius.current ? radius.current : 0.2)
        }
    }, [props.radius]);

    useEffect(() => {
        if(map.current) {
            mapObject.on('click', function (e) {
                setLayer(e.lngLat.lng, e.lngLat.lat, radius.current ? radius.current : 0.2)
            });
            geocoderObject.on('result', function (e) {
                setLayer(e.result.geometry.coordinates[0], e.result.geometry.coordinates[1], radius.current ? radius.current : 0.2)
            });
        }
    }, [mapObject]);

    return (
        <Box>
            <Box className={styles.mapContainer} ref={mapContainerRef}/>
        </Box>
    )
}

export default Map