import {gql} from '@apollo/client';
import {PROTOCOL_ENTITY} from "../entities/protocolEntity";
import {TASK_ENTITY} from "../entities/taskEntity";
import {USER_ENTITY} from "../entities/userEntity";
import {FILE_ENTITY} from "../entities/fileEntity";

export const ON_PROTOCOLS_CHANGED = gql`
  subscription {
    onProtocolsChanged {
        ...ProtocolEntity
        task {
          ...TaskEntity
        }
        user {
          ...UserEntity
        }
        files {
          ...FileEntity
        }
    }
  }
  ${PROTOCOL_ENTITY},${TASK_ENTITY},${USER_ENTITY},${FILE_ENTITY}
`;