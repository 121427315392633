// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./resources/fonts/Mustica/Mustica.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./resources/fonts/Nurom/Nurom.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app_appWrapper__lRSUs {
    height: 100%;
    width: 100%;
    background-color: #f0f2f5;
    overflow-y: auto;
    overflow-x: hidden;
}
.app_appContainer__75Hvr {
    height: 100vh;
}
@font-face {
    font-family: Mustica;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    -webkit-font-smoothing: antialiased;
}
@font-face {
    font-family: Nurom;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    -webkit-font-smoothing: antialiased;
}`, "",{"version":3,"sources":["webpack://./src/app.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;AACA;IACI,oBAAoB;IACpB,4CAA+C;IAC/C,mCAAmC;AACvC;AACA;IACI,kBAAkB;IAClB,4CAA2C;IAC3C,mCAAmC;AACvC","sourcesContent":[".appWrapper {\n    height: 100%;\n    width: 100%;\n    background-color: #f0f2f5;\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n.appContainer {\n    height: 100vh;\n}\n@font-face {\n    font-family: Mustica;\n    src: url(./resources/fonts/Mustica/Mustica.otf);\n    -webkit-font-smoothing: antialiased;\n}\n@font-face {\n    font-family: Nurom;\n    src: url(./resources/fonts/Nurom/Nurom.ttf);\n    -webkit-font-smoothing: antialiased;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appWrapper": `app_appWrapper__lRSUs`,
	"appContainer": `app_appContainer__75Hvr`
};
export default ___CSS_LOADER_EXPORT___;
