import {
    Box,
    Button,
    Divider,
    FilledInput,
    FormControl, FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack, Switch,
    TextField,
    Typography
} from "@mui/material";
import {useUsers} from "../../apollo/hooks/useUsers";
import styles from './users.module.css'
import {useState} from "react";
import {containerElement, itemElement} from "../../stagger";
import {AnimatePresence, motion} from "framer-motion";
import FullpageLoader from "../common/FullPageLoader";
import {useMessages} from "../../apollo/hooks/useMessages";
import {IoSendSharp} from "react-icons/io5";

const Protocol = () => {

    const [prename, setPrename] = useState("")
    const [surname, setSurname] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [role, setRole] = useState("MITARBEITER")

    const [editprename, setEditPrename] = useState("")
    const [editChatId, setEditChatId] = useState("")
    const [editsurname, setEditSurname] = useState("")
    const [editusername, setEditUsername] = useState("")
    const [editrole, setEditRole] = useState("")

    const [editIndex, setEditIndex] = useState(null)

    const {
        me,
        users,
        addUser,
        removeUser,
        updateUser,
        updateNotificationState,
        userRoles,
        loading: usersLoading,
        error: usersError
    } = useUsers()
    const {addMessage, error: messageError} = useMessages()
    if (usersLoading || usersError) return

    if (usersLoading) return <FullpageLoader position={'relative'}/>
    if (usersError || messageError) return <FullpageLoader error position={'relative'}/>

    const handleSetEditUser = (index, user) => {
        setEditIndex(index)
        setEditPrename(user.prename)
        setEditSurname(user.surname)
        setEditChatId(user.chatId)
        setEditUsername(user.username)
        setEditRole(user.role)
    }

    const handleCreateMessage = (message, chatid) => {
        addMessage(message, chatid)
    }

    const handleRemoveUser = (id) => {
        removeUser(id)
    }

    const handleClear = () => {
        setPrename("")
        setSurname("")
        setUsername("")
        setPassword("")
        setRole("MITARBEITER")
        setEditIndex(null)
    }

    const handleAddUser = () => {
        const payload = {
            role: role,
            prename: prename,
            surname: surname,
            username: username,
            password: password
        }
        addUser(payload).then(() => {
            handleClear()
        })
    }

    const handleUpdateNotificationState = (user) => {
        const payload = {
            notifications: !user.notifications,
        }
        updateNotificationState(payload, user._id)
    }

    const handleEditUser = (id) => {
        const payload = {
            role: editrole,
            prename: editprename,
            chatId: editChatId ? editChatId : null,
            surname: editsurname,
            username: editusername
        }
        updateUser(payload, id).then(() => {
            setEditIndex(null)
        })
    }

    const MessageBox = (props) => {
        const [message, setMessage] = useState("")
        return (
            <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="filled-adornment-password">Nachricht an ChatID {props.user.chatId}</InputLabel>
                <FilledInput
                    multiline
                    disabled={!props.user.chatId}
                    onChange={(e) => setMessage(e.target.value)}
                    id="filled-adornment-password"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disabled={!message}
                                aria-label="toggle password visibility"
                                onClick={() => handleCreateMessage(message, props.user.chatId)}
                                edge="end"
                            >
                                <IoSendSharp/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        )
    }

    const renderUsers = () => {
        return users.map((user, index) => (
            <motion.div
                key={index}
                variants={itemElement}
            >
                {editIndex !== index ?
                    <Paper key={index}>
                        <Stack direction={'column'} spacing={1} p={2} divider={<Divider/>}>
                            <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                                <Stack direction={'column'}>
                                    <Typography variant={'body2'}>Vorname Name</Typography>
                                    <Typography variant={'h7'}>{user.prename} {user.surname}</Typography>
                                </Stack>
                                <Stack direction={'column'}>
                                    <Typography variant={'body2'}>Nutzername</Typography>
                                    <Typography variant={'h7'}>{user.username}</Typography>
                                </Stack>
                                <Stack direction={'column'}>
                                    <Typography variant={'body2'}>Nutzerrolle</Typography>
                                    <Typography variant={'h7'}>{user.role}</Typography>
                                </Stack>
                            </Stack>
                            {user.chatId && <MessageBox user={user}/>}
                            {user.chatId &&
                                <Stack px={1} direction={'row'} spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant={'body1'}>Benachrichtigungen</Typography>
                                    <Switch checked={user.notifications}
                                            onChange={() => handleUpdateNotificationState(user)}/>
                                </Stack>}
                            <Stack direction={'row'} spacing={1} justifyContent={'end'}>
                                <Button onClick={() => handleSetEditUser(index, user)} className={styles.button}
                                        color={'primary'}>Bearbeiten</Button>
                                {me.me.role === "ADMIN" &&
                                    <Button onClick={() => handleRemoveUser(user._id)} className={styles.button}
                                            color={'secondary'}>Löschen</Button>}
                            </Stack>
                        </Stack>
                    </Paper>
                    :
                    <Stack direction={'column'} spacing={1}>
                        <Paper key={index}>
                            <Box p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField variant={'filled'} value={editprename}
                                                   onChange={(e) => setEditPrename(e.target.value)}
                                                   fullWidth
                                                   label={'Vorname'}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField variant={'filled'} value={editsurname}
                                                   onChange={(e) => setEditSurname(e.target.value)}
                                                   fullWidth
                                                   label={'Nachname'}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField variant={'filled'} value={editusername}
                                                   onChange={(e) => setEditUsername(e.target.value)}
                                                   fullWidth
                                                   label={'Nutzername'}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField variant={'filled'} value={editChatId}
                                                   onChange={(e) => setEditChatId(e.target.value)}
                                                   fullWidth
                                                   label={'ChatID'}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="filled">
                                            <InputLabel>Nutzerrolle</InputLabel>
                                            <Select
                                                value={editrole}
                                                onChange={(e) => setEditRole(e.target.value)}
                                            >
                                                {userRoles.getUserRoles.map((role, index) => (
                                                    <MenuItem key={index} value={role}>{role}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        <Stack direction={'row'} spacing={1} display={'flex'} justifyContent={'end'}>
                            <Button onClick={() => handleClear()} variant={'contained'}
                                    color={'secondary'}>Abbrechen</Button>
                            <Button onClick={() => handleEditUser(user._id)} variant={'contained'}>Speichern</Button>
                        </Stack>
                    </Stack>
                }
            </motion.div>
        ))
    }

    const renderAddUser = () => {
        return (
            <motion.div
                key={'addUser'}
                variants={itemElement}
            >
                <Stack direction={'column'} spacing={1}>
                    <Paper>
                        <Box p={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField variant={'filled'} value={prename}
                                               onChange={(e) => setPrename(e.target.value)}
                                               fullWidth
                                               label={'Vorname'}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField variant={'filled'} value={surname}
                                               onChange={(e) => setSurname(e.target.value)}
                                               fullWidth
                                               label={'Nachname'}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField variant={'filled'} value={username}
                                               onChange={(e) => setUsername(e.target.value)}
                                               fullWidth
                                               label={'Nutzername'}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField variant={'filled'} value={password}
                                               onChange={(e) => setPassword(e.target.value)}
                                               fullWidth
                                               label={'Passwort'}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel>Nutzerrolle</InputLabel>
                                        <Select
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                        >
                                            {userRoles.getUserRoles.map((role, index) => (
                                                <MenuItem key={index} value={role}>{role}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Stack direction={'row'} spacing={1} display={'flex'} justifyContent={'end'}>
                        <Button disabled={!prename || !surname || !role || !password || !username}
                                onClick={() => handleAddUser()} variant={'contained'}>Nutzer hinzufügen</Button>
                    </Stack>
                </Stack>
            </motion.div>
        )
    }

    return (
        <motion.div
            key={1}
            variants={containerElement}
            initial="hidden"
            animate="show"
            exit="hidden"
        >
            <AnimatePresence mode={'wait'}>
                <Stack direction={'column'} spacing={1}>
                    {renderUsers()}
                    {renderAddUser()}
                </Stack>
            </AnimatePresence>
        </motion.div>
    )
}

export default Protocol