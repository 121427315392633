import React from 'react';

import {LOGIN_USER} from '../../../apollo/mutations/loginUser';
import {useMutation} from "@apollo/client";

import {Alert, Box, Button, Container, Paper, Stack, TextField} from "@mui/material";
import styles from "./login.module.css";

const Login = () => {

    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [error, setError] = React.useState("")

    const [loginUser] = useMutation(LOGIN_USER);

    const handleLoginAttempt = () => {
        loginUser({
            variables: {
                username: username,
                password: password
            }
        }).then((res) => {
            localStorage.setItem("jwt", res.data.loginUser)
            window.location.reload()
        }).catch(e => {
            if (e.graphQLErrors)
                setError("Falsche Anmeldedaten, bitte versuchen Sie es erneut!");
            setUsername("")
            setPassword("")
            return false;
        })
    }

    return (
        <Box className={styles.loginWrapper} p={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Container maxWidth={'xs'}>
                <Paper>
                    <Box p={1}>
                        <Stack direction={'column'} spacing={1} textAlign={'center'}>
                            {error && !username && <Box>
                                <Alert severity="error">{error}</Alert>
                            </Box>}
                            <TextField value={username} onChange={(e) => setUsername(e.target.value)}
                                       fullWidth variant={"filled"}
                                       label={"Nutzername"}/>
                            <TextField value={password} type={"password"}
                                       onChange={(e) => setPassword(e.target.value)}
                                       fullWidth variant={"filled"}
                                       label={"Passwort"}/>
                            <Button disabled={!username || !password} onClick={() => handleLoginAttempt()}
                                    fullWidth
                                    variant={"contained"} color={"primary"}
                                    size={"large"}>Anmelden</Button>
                        </Stack>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
}

export default Login;