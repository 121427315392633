import {useAgendas} from "../../apollo/hooks/useAgendas";
import FullpageLoader from "../common/FullPageLoader";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Chip,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import moment from "moment/moment";
import {overDue} from "../../dateFunctions";
import {useUsers} from "../../apollo/hooks/useUsers";
import {IoIosCloseCircleOutline} from "react-icons/io";
import User from "../common/User";

const Agenda = (props) => {

    const {agendas, removeAgenda, updateAgenda, loading: agendasLoading, error: agendasError} = useAgendas()
    const {me, loading: usersLoading, error: usersError} = useUsers()

    if (agendasLoading || usersLoading) return <FullpageLoader/>
    if (agendasError || usersError) return <FullpageLoader error/>

    const handleUpdateAgenda = (item) => {
        const payload = {
            task: item.task._id,
            assigned: item.assigned,
            agenda: item.agenda,
            startdate: item.startdate,
            enddate: item.enddate,
            finished: !item.finished
        }
        updateAgenda(payload, item._id)
        if (!item.finished && props.onChange) {
            props.onChange(item)
        }
    }

    const renderAgendaState = (item) => {
        switch (item.finished) {
            case true:
                return (
                    <Stack direction={'column'} spacing={1}>
                        <Box>
                            <Chip color={'primary'} label={"Erledigt"} sx={{backgroundColor: '#419a1d'}}/>
                        </Box>
                        <Typography variant={'body2'}>am {moment(item.updated_at).format('L')}</Typography>
                    </Stack>
                )
            default:
                return (
                    <Chip variant={'outlined'} label={"Ausstehend"}/>
                )
        }
    }

    const renderAgenda = () => {

        let agendaList = agendas
            .filter(item => props.referenceDate ? item.finished && moment(item.updated_at).startOf('day').isSame(moment(props.referenceDate).startOf('day')) : true)
            .filter(item => item.task._id === props.taskId)
            .filter(item => !props.showAll ? item.assigned.some(x => x === props.user) && moment(item.startdate).startOf('day').startOf('day').isSameOrAfter(moment().startOf('day')) : true)
            .sort((a, b) => moment(a.enddate) - moment(b.enddate))

        if (agendaList.length === 0) {
            return (
                <Card>
                    <CardContent>
                        <Box p={1} textAlign={'center'}>
                            <Typography variant={'body2'}>Keine Agenda vorhanden.</Typography>
                        </Box>
                    </CardContent>
                </Card>
            )
        }

        return (
            <Card>
                <CardContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant={'h7'}>
                                        Agenda
                                    </Typography>
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Typography variant={'h7'}>
                                        Status
                                    </Typography>
                                </TableCell>
                                {me.me.role === "ADMIN" &&
                                    <TableCell align={'right'}>
                                        <Typography variant={'h7'}>
                                            Aktion
                                        </Typography>
                                    </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                agendaList.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell>
                                            <Stack direction={'column'} spacing={1}>

                                                <Stack direction={'row'} spacing={2}>
                                                    <Stack direction={'column'}>
                                                        <Typography
                                                            variant={'body2'}>Bis</Typography>
                                                        <Typography
                                                            color={overDue(item.enddate) ? "secondary" : "#000000"}
                                                            variant={'h7'}>{moment(item.enddate).format('L')}</Typography>
                                                    </Stack>
                                                    {props.showNames &&
                                                        <Stack direction={'column'} spacing={1}>
                                                            {item.assigned.map((user, index) => (
                                                                <User key={index} type={'text'} userId={user}/>
                                                            ))}
                                                        </Stack>
                                                    }
                                                    {props.showCustomer &&
                                                        <Stack direction={'column'}>
                                                            <Typography
                                                                variant={'body2'}>Kunde</Typography>
                                                            <Stack direction={'column'} spacing={1}>
                                                                <Typography variant={'h7'}>
                                                                    {item.task.customer}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>}
                                                </Stack>
                                                <Stack direction={'column'}>
                                                    <Typography
                                                        variant={'body2'}>Agenda</Typography>
                                                    <Typography
                                                        variant={'body1'}>{item.agenda}</Typography>
                                                </Stack>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <Box>
                                                {props.showAll || props.noEdit ? item.finished ? renderAgendaState(item) : renderAgendaState(item) :
                                                    <Checkbox onClick={() => handleUpdateAgenda(item)}
                                                              checked={item.finished}/>}
                                            </Box>
                                        </TableCell>
                                        {(me.me.role === "ADMIN" || me.me.role === "KOORDINATOR") &&
                                            <TableCell>
                                                <IconButton
                                                    color={'secondary'}
                                                    edge='end'
                                                    aria-label='delete'
                                                    onClick={() => removeAgenda(item._id)}
                                                >
                                                    <IoIosCloseCircleOutline/>
                                                </IconButton>
                                            </TableCell>}
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        )

    }

    return renderAgenda()

}

export default Agenda