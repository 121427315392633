import {Chip} from "@mui/material";

const TaskType = (props) => {
    switch(props.type){
        case "NEUAUFTRAG":
            return(
                <Chip color={'primary'} label={'Neuauftrag'}/>
            )
        case "REPARATUR":
            return(
                <Chip color={'primary'} label={'Reparatur'}/>
            )
        case "WARTUNG":
            return(
                <Chip color={'primary'} label={'Wartung'}/>
            )
        default:
            return "Fehler"
    }
}

export default TaskType