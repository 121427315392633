import {Box, Button, Paper, Stack, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {useTasks} from "../../apollo/hooks/useTasks";
import {MobileDatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {useProtocols} from "../../apollo/hooks/useProtocols";
import {useUsers} from "../../apollo/hooks/useUsers";
import {useNavigate, useParams} from "react-router-dom";
import {useOrders} from "../../apollo/hooks/useOrders";
import OrderHistory from "../Orders/OrderHistory";
import {useFiles} from "../../apollo/hooks/useFiles";
import {useGlobalStore} from "../../store";
import FileUpload from "../FileUpload";
import FullpageLoader from "../common/FullPageLoader";
import Agenda from "../Agenda/Agenda";
import Time from "../Time";
import {useTimes} from "../../apollo/hooks/useTimes";
import {checkIfPointInRadius} from "../Map/checkIfPointInRadius";
import {MdOutlineConstruction} from "react-icons/md";
import {BsArrowRight} from "react-icons/bs";
import Compressor from 'compressorjs';

const CreateProtocol = () => {

    const router = useNavigate()
    const routeParams = useParams()

    const selectedFile = useGlobalStore((state) => state.selectedFile)
    const clearSelectedFile = useGlobalStore((state) => state.clearSelectedFile)

    const [requestDate, setRequestDate] = useState(moment().add(3, 'days'))
    const [step, setStep] = useState(routeParams.id ? 1 : 0)
    const [loading, setLoading] = useState(false)
    const [task, setTask] = useState(routeParams.id ? routeParams.id : null)
    const [order, setOrder] = useState("")
    const [progress, setProgress] = useState("")
    const {tasks, enabledTasks, loading: tasksLoading, error: tasksError} = useTasks()
    const {orders, addOrder, orderStates, loading: ordersLoading, error: ordersError} = useOrders()
    const {protocols, addProtocol, loading: protocolsLoading, error: protocolsError} = useProtocols()
    const {currentTime, checkOut, myTimes, loading: timesLoading, error: timesError} = useTimes()
    const {uploadFile, loading: filesLoading, error: filesError} = useFiles()
    const {me, loading: usersLoading, error: usersError} = useUsers()

    if (tasksLoading || protocolsLoading || usersLoading || ordersLoading || filesLoading || timesLoading) return <FullpageLoader/>
    if (tasksError || protocolsError || usersError || ordersError || filesError || timesError) return <FullpageLoader
        error/>

    const handleSendProtocol = () => {
        setLoading(true)
        const payload = {
            user: me.me._id,
            task: task,
            progress: progress,
        }
        const orderPayload = {
            user: me.me._id,
            task: task,
            order: order,
            requestdate: requestDate,
            state: orderStates.getOrderStates[0]
        }
        if (routeParams.id && routeParams.id === currentTime.task._id) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let selectedTask = enabledTasks.find(x => x._id === currentTime.task._id)
                if (checkIfPointInRadius([selectedTask.long, selectedTask.lat], selectedTask.radius, [position.coords.longitude, position.coords.latitude])) {
                    if (order) addOrder(orderPayload)
                    addProtocol(payload).then((res) => {
                        checkOut(currentTime._id).then(() => {
                            selectedFile.map((file) => {
                                const inputPayload = {
                                    filename: file.name,
                                    permission: file.permission,
                                    mimetype: file.type,
                                    protocol: res.data?.createProtocol._id.toString(),
                                    user: me.me._id
                                }
                                new Compressor(file, {
                                    quality: 0.2,
                                    success: (compressedResult) => {
                                        uploadFile(inputPayload, compressedResult).then(() => {
                                            clearSelectedFile()
                                            setLoading(false)
                                            router('/')
                                        })
                                    },
                                });
                            })
                        })
                    })
                } else {
                    alert("Du befindest dich nicht auf bzw. in der Nähe der vorgegebenen Baustelle.")
                    setLoading(false)
                }
            })
        } else {
            if (order) addOrder(orderPayload)
            addProtocol(payload).then((res) => {
                selectedFile.map((file) => {
                    const inputPayload = {
                        filename: file.name,
                        permission: file.permission,
                        mimetype: file.type,
                        protocol: res.data?.createProtocol._id.toString(),
                        user: me.me._id
                    }
                    new Compressor(file, {
                        quality: 0.2,
                        success: (compressedResult) => {
                            uploadFile(inputPayload, compressedResult).then(() => {
                                clearSelectedFile()
                                setLoading(false)
                                router('/')
                            })
                        },
                    });
                })
            })
        }
    }

    let sortedTasks = myTimes.reduce(function (o, cur) {
        if (!o) return o
        var occurs = []
        occurs = o.find(ob => moment(ob.day).isSame(moment(cur.starttime), 'day'));
        if (occurs) {
            if (cur.type === "CHECKINCHECKOUT") {
                if (!occurs.tasks.find(task => task._id == cur.task._id)) {
                    occurs.tasks.push(cur.task)
                }
            }
        } else {
            if (cur.task) {
                var obj = {
                    day: cur.starttime,
                    tasks: [cur.task],
                };
                o = o.concat(obj);
            }
        }
        return o;
    }, [])

    const renderStep = () => {
        let tasks = sortedTasks.find(task => moment(task.day).isSame(moment(), 'day'))?.tasks
        switch (step) {
            case 0:
                return (
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Bei welchem Auftrag warst du heute
                                            tätig?</Typography>
                                    </Box>
                                    {tasks && tasks
                                        .filter(task => task.assigned.some(assigned => assigned === me.me._id))
                                        .map((x, index) => (
                                            <Button
                                                disabled={protocols.find(protocol => protocol.task._id === x._id && protocol.user._id === me.me._id && moment().startOf('day').isSame(moment(protocol.created_at).startOf('day')))}
                                                onClick={() => {
                                                    setTask(x._id)
                                                    increaseStep()
                                                }} key={index}>
                                                <Stack direction={'column'}>
                                                    <Typography variant={'h4'} color={'inherit'}>
                                                        <MdOutlineConstruction/>
                                                    </Typography>
                                                    <Typography variant={'h7'} color={'inherit'}>
                                                        {x.customer}
                                                    </Typography>
                                                    <Stack direction={'row'} spacing={1} divider={<BsArrowRight/>}
                                                           alignItems={'center'}>
                                                        <Typography variant={'body2'} color={'inherit'}>
                                                            {moment(x?.startdate).format('L')}
                                                        </Typography>
                                                        <Typography variant={'body2'} color={'inherit'}>
                                                            {moment(x?.enddate).format('L')}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Button>
                                        ))}
                                </Stack>
                            </Box>
                        </Paper>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button disabled={!task} variant={'contained'}
                                        onClick={() => increaseStep()}>Weiter</Button>
                            </Stack>
                        </Box>
                    </Stack>
                )
            case 1:
                return (
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Was hast du heute geschafft?</Typography>
                                    </Box>
                                    <Agenda user={me.me._id} taskId={task}
                                            onChange={(e) => setProgress(progress + " " + e.agenda + " erledigt.")}/>
                                    <TextField multiline rows={5} value={progress}
                                               onChange={(e) => setProgress(e.target.value)} fullWidth
                                               variant={'filled'} label={'Eingabe'}/>
                                </Stack>
                            </Box>
                        </Paper>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button disabled={routeParams.id} variant={'contained'}
                                        onClick={() => decreaseStep()}>Zurück</Button>
                                <Button disabled={!progress} variant={'contained'}
                                        onClick={() => increaseStep()}>Weiter</Button>
                            </Stack>
                        </Box>
                    </Stack>
                )
            case 2:
                return (
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Bitte lade Bilder deiner Arbeit hoch.</Typography>
                                    </Box>

                                    <FileUpload disablePermissions/>

                                </Stack>
                            </Box>
                        </Paper>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button variant={'contained'} onClick={() => decreaseStep()}>Zurück</Button>
                                <Button disabled={selectedFile.length === 0} variant={'contained'}
                                        onClick={() => increaseStep()}>Weiter</Button>
                            </Stack>
                        </Box>
                    </Stack>
                )
            case 3:
                return (
                    <Stack direction={'column'} spacing={1} position={'relative'}>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Lass uns wissen, ob du noch etwas
                                            benötigst!</Typography>
                                    </Box>
                                    <TextField multiline rows={5} value={order}
                                               onChange={(e) => setOrder(e.target.value)} fullWidth
                                               variant={'filled'} label={'Wird etwas benötigt?'}/>
                                    <Stack direction={'column'} spacing={1}>
                                        <Box px={1}>
                                            <Typography variant={'h7'}>Zu wann benötigst du deine
                                                Bestellung?</Typography>
                                        </Box>
                                        <MobileDatePicker minDate={moment().add(1, 'days')}
                                                          maxDate={moment().isAfter(moment(tasks.find(x => x._id === task).enddate)) && tasks.find(x => x._id === task).state === "ENABLED" ? moment().add(3, 'days') : moment(tasks.find(x => x._id === task).enddate)}
                                                          disablePast disabled={!order} slotProps={{
                                            textField: {
                                                label: 'Gewünschtes Lieferdatum',
                                                variant: 'filled',
                                                fullWidth: true
                                            }
                                        }} displayWeekNumber closeOnSelect value={requestDate}
                                                          onChange={(value) => {
                                                              setRequestDate(value)
                                                          }}/>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Paper>
                        <Paper>
                            <Box p={1}>
                                <Stack direction={'column'} spacing={1}>
                                    <Box px={1}>
                                        <Typography variant={'h7'}>Bestellverlauf
                                            für {tasks.find(x => x._id === task).customer}</Typography>
                                    </Box>
                                    <OrderHistory orders={orders} task={task}/>
                                </Stack>
                            </Box>
                        </Paper>
                        <Box display={'flex'} justifyContent={'end'}>
                            <Stack direction={'row'} spacing={1}>
                                <Button variant={'contained'} onClick={() => decreaseStep()}>Zurück</Button>
                                <Button disabled={loading} variant={'contained'}
                                        onClick={() => handleSendProtocol()}>Tagesbericht absenden</Button>
                            </Stack>
                        </Box>
                    </Stack>
                )
            default:
                return "Error"
        }
    }

    const increaseStep = () => {
        setStep(step + 1)
    }

    const decreaseStep = () => {
        setStep(step - 1)
    }

    return (
        <>
            {
                tasks.filter(task => task.assigned.some(assigned => assigned === me.me._id)).length === 0 ?
                    <Box my={1}>
                        <Paper>
                            <Box p={2} textAlign={'center'}>
                                <Typography variant={'body2'}>Keine Aufträge vorhanden.</Typography>
                            </Box>
                        </Paper>
                    </Box>
                    :
                    <Stack direction={'column'} spacing={1}>
                        {routeParams.id && <Time disableActions/>}
                        {renderStep()}
                    </Stack>
            }
        </>
    )
}

export default CreateProtocol