import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {GET_ALL_FEEDBACKS} from "../queries/getAllFeedbacks";
import {GET_FEEDBACK_STATES} from "../queries/getFeedbackStates";
import {ON_FEEDBACKS_CHANGED} from "../subscriptions/onFeedbacksChanged";
import {CREATE_FEEDBACK} from "../mutations/createFeedback";
import {UPDATE_FEEDBACK_STATE} from "../mutations/updateFeedbackState";
import {REMOVE_FEEDBACK} from "../mutations/removeFeedback";
import {useUsers} from "./useUsers";

export const useFeedbacks = () => {

    const {me} = useUsers()
    const fetchQuery = GET_ALL_FEEDBACKS

    const {
        data: dataQuery,
        loading: loadingQuery,
        error: errorQuery,
        refetch: refetchQuery
    } = useQuery(fetchQuery, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {
        data: feedbackStates,
        loading: loadingFeedbackStates,
        error: errorFeedbackStates,
    } = useQuery(GET_FEEDBACK_STATES, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    })

    const {data: allFeedbacksSubData} = useSubscription(ON_FEEDBACKS_CHANGED, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    });

    let feedbackData = allFeedbacksSubData ? allFeedbacksSubData?.onFeedbacksChanged : dataQuery ? dataQuery?.getAllFeedbacks : [];

    //console.log("OrderQuery", allOrdersSubData?.onOrdersChanged, dataQuery?.getAllOrders)

    const [add, {error: errorAdd}] = useMutation(
        CREATE_FEEDBACK,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('feedback created!')
            },
        },
    );

    const [updateState, {error: errorStateUpdate}] = useMutation(
        UPDATE_FEEDBACK_STATE,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('feedback state updated!')
            },
        },
    );

    const [remove, {error: errorRemove}] = useMutation(
        REMOVE_FEEDBACK,
        {
            errorPolicy: "all",
            fetchPolicy: 'no-cache',
            onCompleted: (res) => {
                if (!res) return
                refetchQuery()
                console.log('feedback removed!')
            },
        },
    );

    return {
        feedbacks: feedbackData,
        myFeedbacks: feedbackData.filter(feedback => feedback.user._id === me?.me._id),
        feedbackStates: feedbackStates,
        loading: loadingQuery || loadingFeedbackStates,
        error: errorQuery || errorAdd || errorRemove || errorStateUpdate || errorFeedbackStates,
        addFeedback: (input) => add({
            variables: {
                input: input
            }
        }),
        updateFeedbackState: (input, id) => updateState({
            variables: {
                input: input,
                _id: id,
            }
        }),
        removeFeedback: (id) => remove({
            variables: {
                _id: id
            }
        })
    }
}