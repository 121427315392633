import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem, Paper,
    Select,
    Stack,
    Typography
} from "@mui/material";
import styles from "./files.module.css";
import {AiFillEye} from "react-icons/ai";
import {useFiles} from "../../apollo/hooks/useFiles";
import {MdClose} from "react-icons/md";
import {useUsers} from "../../apollo/hooks/useUsers";
import FullpageLoader from "../common/FullPageLoader";
import {PermissionType} from "../FileUpload/PermissionType";

const Files = (props) => {

    const {
        files,
        removeFile,
        updateFilePermissions,
        filePermissions,
        loading: filesLoading,
        error: filesError
    } = useFiles()
    const {me, loading: usersLoading, error: usersError} = useUsers()

    if (filesLoading || usersLoading) return <FullpageLoader position={'relative'}/>
    if (filesError || usersError) return <FullpageLoader error position={'relative'}/>

    const handleChangeFilePermission = (permission, fileId) => {
        const payload = {
            permission: permission
        }
        updateFilePermissions(payload, fileId)
    }

    const renderFileType = (file) => {
        switch (file.mimetype) {
            case "image/png":
                return (
                    <img alt={"png"} style={{width: '100%', height: '100%'}}
                         src={window.env.FILES_URL + "/" + (file.protocol._id ? file.protocol._id : file.task._id ? file.task._id : file.order._id) + "/" + file.filename}/>
                )
            case "image/jpeg":
                return (
                    <img alt={"jpeg"} style={{width: '100%', height: '100%'}}
                         src={window.env.FILES_URL + "/" + (file.protocol._id ? file.protocol._id : file.task._id ? file.task._id : file.order._id) + "/" + file.filename}/>
                )
            case "image/gif":
                return (
                    <img alt={"gif"} style={{width: '100%', height: '100%'}}
                         src={window.env.FILES_URL + "/" + (file.protocol._id ? file.protocol._id : file.task._id ? file.task._id : file.order._id) + "/" + file.filename}/>
                )
            default:
                return (
                    <Box p={1} height={'100%'}>
                        <Stack height={'100%'} direction={'column'} spacing={1} alignItems={'center'}
                               justifyContent={'space-between'}>
                            <Typography variant={'body2'}>
                                {file.mimetype}
                            </Typography>
                            <Typography variant={'body2'}>
                                {file.filename}
                            </Typography>
                        </Stack>
                    </Box>
                )
        }
    }

    const renderFiles = () => {
        let fileItems =
            files?.filter(file => props?.allFiles ? true : file.protocol ? file?.protocol?._id === props.id : file?.task?._id === props.id ? file?.task?._id : file?.order?._id === props.id)
                .filter(file => file.permission === "ALL" || me.me.role === "ADMIN" ? true : file.permission === me.me.role)

        if (fileItems.length === 0) {
            return (
                <Card>
                    <CardContent>
                        <Box p={1} textAlign={'center'}>
                            <Typography variant={'body2'}>Keine Dokumente & Bilder vorhanden.</Typography>
                        </Box>
                    </CardContent>
                </Card>
            )
        }

        return (
            <Paper sx={{ boxShadow: 'none' }}>
                <Box p={1}>
                    <Grid container spacing={1}>
                        {
                            fileItems.map((file, index) => (
                                <Grid key={index} item xs={4}>
                                    <Stack direction={'column'} spacing={1} height={'100%'}>
                                        <Box className={styles.file}>
                                            <Box className={styles.viewButton} width={'100%'} height={'100%'}
                                                 display={'flex'}
                                                 justifyContent={'center'} alignItems={'center'}>
                                                <IconButton
                                                    onClick={() => window.open(
                                                        window.env.FILES_URL + "/" + (file.protocol._id ? file.protocol._id : file.task._id ? file.task._id : file.order._id) + "/" + file.filename,
                                                        '_blank' // <- This is what makes it open in a new window.
                                                    )}
                                                    style={{color: '#ffffff'}}
                                                >
                                                    <AiFillEye/>
                                                </IconButton>
                                            </Box>
                                            {me.me.role === "ADMIN" &&
                                                <Box className={styles.deleteButton} width={'100%'} display={'flex'}
                                                     justifyContent={'end'}>
                                                    <IconButton
                                                        color={'secondary'}
                                                        onClick={() => removeFile(file._id)}
                                                    >
                                                        <MdClose/>
                                                    </IconButton>
                                                </Box>}
                                            {renderFileType(file)}
                                        </Box>
                                        {(me.me.role === "ADMIN" || me.me.role === "KOORDINATOR") &&
                                            <Box>
                                                <Card>
                                                    <CardContent>
                                                        <FormControl fullWidth variant="filled" size={'small'}>
                                                            <InputLabel>Berechtigung</InputLabel>
                                                            <Select
                                                                value={file.permission}
                                                                onChange={(e) => handleChangeFilePermission(e.target.value, file._id)}
                                                            >
                                                                {filePermissions?.getFilePermissions.map((type, index) => (
                                                                    <MenuItem key={index} value={type}><PermissionType
                                                                        type={type}/></MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </CardContent>
                                                </Card>
                                            </Box>}
                                    </Stack>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Paper>
        )
    }

    return renderFiles()
}

export default Files